import { Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from './api.service';
import { UrlService } from './url.service';

@Injectable({
  providedIn: 'root',
})
export class VeiculoService {
  private _apiService = inject(ApiService);
  private _urlService = inject(UrlService);

  getMarcas(payload: any): Observable<any> {
    console.log('test')
    const url = this._urlService.getApiVeiculoMarcas();
    return this._apiService.post(url, payload);
  }

  getModelos(payload: any): Observable<any> {
    const url = this._urlService.getApiVeiculoModelo();

    return this._apiService.post(url, payload);
  }

  getItemListaMarcas(id: string, list: Array<any>): any | null {
    return list.find(veiculo => veiculo.identificadorMarcaVeiculo === id) || null;
  }

  getItemListaModelo(id: string, list: Array<any>): any | null {
    return list.find(veiculo => veiculo.identificadorModeloVeiculo === id) || null;
  }

  getPlaca(): string {
    const letters = 'ABCDEFGHIJ';
    const firstPart = Array.from({ length: 3 }, () => letters[Math.floor(Math.random() * letters.length)]).join('');
    const number = Math.floor(Math.random() * 10);
    const secondPart = String(number);
    const thirdPart = letters[Math.floor(Math.random() * letters.length)];
    const lastPart = Array.from({ length: 2 }, () => Math.floor(Math.random() * 10).toString()).join('');
    const placa = firstPart + secondPart + thirdPart + lastPart;

    return placa;
  }

  postCreditoPlaca(payload: any): Observable<any> {
    const url = this._urlService.getApiCreditoPlaca();

    return this._apiService.post(url, payload);
  }

  postValidarPlaca(payload: any): Observable<any> {
    const url = this._urlService.getApiValidarPlaca();

    return this._apiService.post(url, payload);
  }

  validarPlaca(control: any): any {
    const placa = control.value.toUpperCase();
    const length = placa.length;
    const regex1 = /^[A-S]{3}\d{4}$/;
    const regex2 = /^[A-J]{3}\d[A-K]\d$/;

    if (length <= 0) {
      return { invalidEmpty: true };
    }

    if (length !== 7) {
      return { invalidFormat: true };
    }

    if (typeof placa[4] === 'string') {
      if (regex2.test(placa)) {
        return { invalidPlate: true };
      }
    } else {
      if (!regex1.test(placa)) {
        return { invalidPlate: true };
      }
    }

    return null;
  }
}
