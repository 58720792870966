import { Injectable, inject } from "@angular/core";
import { ApiService } from "./api.service";

@Injectable({
    providedIn: 'root'
})
export class UrlService {
    protected _apiService = inject(ApiService);

    readonly codigoOperacaoImagem = 'assets/images/codigo_operacao.png';
    readonly contratoAdesao: string = "https://semparar.vteximg.com.br/arquivos/contrato-de-adesao-ao-produto-tagcaixa-pessoa-fisica-23-06-2022.pdf";
    readonly emailCaixaImagem: string = 'https://desbloqueio.caixaprepagos.com.br/assets/images/caixa_pre_pagos.png';
    readonly ipify: string = 'https://api.ipify.org?format=json';
    readonly listaBancos = 'assets/mocks/bancos.json';
    readonly listaCodigosBancarios = 'assets/mocks/codigos_bancarios.json';
    readonly listaDiasNumeral = 'assets/mocks/dias_pagamento.json';
    readonly listaDuvidas = 'assets/mocks/duvidas.json';
    readonly listaEstados: string = 'assets/mocks/estados.json';
    readonly listaOndeUtilizar = 'assets/mocks/onde-utilizar.json';
    readonly listaMeses: string = 'assets/mocks/meses.json';
    readonly listaPlanos: string = 'assets/mocks/planos.json';
    readonly listaValorRecarga: string = 'assets/mocks/recargas.json';
    readonly videoTutorialDesbloquearTag = 'assets/videos/desbloquear_tag_caixa.mp4';

    private _urlInterna = window.location.origin;

    getApiAccessToken(): string {
        return this._urlInterna.includes('qa') || this._urlInterna.includes('dev') || this._urlInterna.includes('localhost')
            ? 'https://cpp-homolog-internal.apisemparar.com.br/ativacao-tag-loterica/v1/usuarios/autenticar'
            : 'https://cpp-prod-internal.apisemparar.com.br/ativacao-tag-loterica/v1/usuarios/autenticar';
    }

    getApiClienteCadastrar(): string {
        return this._urlInterna.includes('qa') || this._urlInterna.includes('dev') || this._urlInterna.includes('localhost')
            ? `https://homolog.apisemparar.com.br/varejo/v1/cliente/cadastrar`
            : `https://prod.apisemparar.com.br/varejo/v1/cliente/cadastrar`;
    }

    getApiCreditoCep(): string {
        return this._urlInterna.includes('qa') || this._urlInterna.includes('dev') || this._urlInterna.includes('localhost')
            ? `https://homolog.apisemparar.com.br/motor-credito/v1/credito/cep`
            : `https://prod.apisemparar.com.br/motor-credito/v1/credito/cep`;
    }

    getApiCreditoCpf(): string {
        return this._urlInterna.includes('qa') || this._urlInterna.includes('dev') || this._urlInterna.includes('localhost')
            ? `https://homolog.apisemparar.com.br/motor-credito/v1/credito/cpf`
            : `https://prod.apisemparar.com.br/motor-credito/v1/credito/cpf`;
    }

    getApiCreditoPlaca(): string {
        return this._urlInterna.includes('qa') || this._urlInterna.includes('dev') || this._urlInterna.includes('localhost')
            ? `https://homolog.apisemparar.com.br/motor-credito/v1/credito/placa`
            : `https://prod.apisemparar.com.br/motor-credito/v1/credito/placa`;
    }

    getApiDispositivoObter(): string {
        return this._urlInterna.includes('qa') || this._urlInterna.includes('dev') || this._urlInterna.includes('localhost')
            ? `https://homolog.apisemparar.com.br/site/v1/dispositivo/obter`
            : `https://prod.apisemparar.com.br/site/v1/dispositivo/obter`;
    }

    getApiEndereco(cep: string): string {
        return `https://viacep.com.br/ws/${cep}/json/`;
    }

    getApiKeyLog(): string {
        return this._urlInterna.includes('qa') || this._urlInterna.includes('dev') || this._urlInterna.includes('localhost')
            ? 'https://cpp-homolog-internal.apisemparar.com.br/ativacao-tag-loterica/v1/parametros'
            : 'https://cpp-prod-internal.apisemparar.com.br/ativacao-tag-loterica/v1/parametros';
    }

    getApiLogAtivacao(): string {
        return this._urlInterna.includes('qa') || this._urlInterna.includes('dev') || this._urlInterna.includes('localhost')
            ? 'https://cpp-homolog-internal.apisemparar.com.br/ativacao-tag-loterica/v1/log-ativacao'
            : 'https://cpp-prod-internal.apisemparar.com.br/ativacao-tag-loterica/v1/log-ativacao';
    }

    getApiPagamentoAuthZero(): string {
        return this._urlInterna.includes('qa') || this._urlInterna.includes('dev') || this._urlInterna.includes('localhost')
            ? `https://homolog.apisemparar.com.br/validacao/v2/pagamento/auth-zero`
            : `https://prod.apisemparar.com.br/validacao/v2/pagamento/auth-zero`;
    }

    getApiValidarPlaca(): string {
        return this._urlInterna.includes('qa') || this._urlInterna.includes('dev') || this._urlInterna.includes('localhost')
            ? `https://homolog.apisemparar.com.br/varejo/v1/placa/validar`
            : `https://prod.apisemparar.com.br/varejo/v1/placa/validar`;
    }

    getApiPedidoCadastrar(): string {
        return this._urlInterna.includes('qa') || this._urlInterna.includes('dev') || this._urlInterna.includes('localhost')
            ? `https://homolog.apisemparar.com.br/varejo/v1/pedido/cadastrar`
            : `https://prod.apisemparar.com.br/varejo/v1/pedido/cadastrar`;
    }

    getApiPedidoanalisar(): string {
        return this._urlInterna.includes('qa') || this._urlInterna.includes('dev') || this._urlInterna.includes('localhost')
            ? `https://homolog.apisemparar.com.br/varejo/v1/pedido/analisar`
            : `https://prod.apisemparar.com.br/varejo/v1/pedido/analisar`;
    }

    getApiPedidoAtribuir(): string {
        return this._urlInterna.includes('qa') || this._urlInterna.includes('dev') || this._urlInterna.includes('localhost')
            ? `https://homolog.apisemparar.com.br/varejo/v1/pedido/atribuir`
            : `https://prod.apisemparar.com.br/varejo/v1/pedido/atribuir`;
    }

    getApiRecaptcha(): string {
        return `https://recaptchaenterprise.googleapis.com/v1/projects/caixa-tag/assessments?key=AIzaSyBpIhSet0A06GAa4eEULikjUOzVEbDSXIA`;
    }

    getApiTokenizing(): string {
        return this._urlInterna.includes('qa') || this._urlInterna.includes('dev') || this._urlInterna.includes('localhost')
            ? `https://cyptgw-hml.fleetcor.com.br/api/v1/tokenizing`
            : `https://cyptgw.fleetcor.com.br/api/v1/tokenizing`;
    }

    getApiVeiculoMarcas(): string {
        return this._urlInterna.includes('qa') || this._urlInterna.includes('dev') || this._urlInterna.includes('localhost')
            ? `https://homolog.apisemparar.com.br/dominios/v1/veiculo/marcas`
            : `https://prod.apisemparar.com.br/dominios/v1/veiculo/marcas`;
    }

    getApiVeiculoModelo(): string {
        return this._urlInterna.includes('qa') || this._urlInterna.includes('dev') || this._urlInterna.includes('localhost')
            ? `https://homolog.apisemparar.com.br/dominios/v1/veiculo/modelos`
            : `https://prod.apisemparar.com.br/dominios/v1/veiculo/modelos`;
    }

    getUrlCyberSourceHeader(documentId: string): string {
        const cyberSourceId = this._apiService.getUrlCyberSourceChave();
        return `https://h.online-metrix.net/fp/tags.js?org_id=${cyberSourceId}&session_id=semparar_br${documentId}`;
    }

    getUrlCyberSourceIframe(documentId: string): string {
        const cyberSourceId = this._apiService.getUrlCyberSourceChave();
        return `https://h.online-metrix.net/fp/tags?org_id=${cyberSourceId}&session_id=semparar_br${documentId}`;
    }
}