import { Injectable, inject } from "@angular/core";
import { FormaPagamentoEnum } from "../enuns/forma-pagamento.enum";
import { DadosInterface } from "../models/dados.interface";
import { ApiService } from "./api.service";
import { PagamentoService } from "./pagamento.service";
import { PedidoService } from "./pedido.service";
import { UtilService } from "./util.service";
import { PlanoInterface } from "../models/plano.interface";

@Injectable({ providedIn: 'root' })
export class PayloadService {
    private _apiService = inject(ApiService);
    private _pagamentoService = inject(PagamentoService);
    private _pedidoService = inject(PedidoService);
    private _utilService = inject(UtilService);

    readonly listaPlanos: string = 'assets/mocks/planos.json';

    dados: DadosInterface = {} as DadosInterface;

    payloadCreditoCpf(cpf: string): object {
        return {
            cpf: cpf,
            canalVenda: 10005,
            empresa: 'CAIXA',
            filtros: {
                tipoCliente: true,
                idModalidadePagamento: true,
                idFormaPagamento: true
            },
        };
    }

    payloadApiKeyLog(cpf: string): object {
        return {
            documento: cpf
        };
    }

    payloadAccessToken(cpf: string, dispositivo: string): object {
        return {
            documento: cpf,
            channel: 1
        };
    }

    payloadDispositivoObter(dispositivo: string): object {
        return dispositivo.length === 11
            ? { numeroIdentificador: dispositivo.substring(0, 10) }
            : { numeroIdentificadorTid: dispositivo };
    }

    payloadMarcas(): object {
        return {
            tipoVeiculo: 1
        };
    }

    payloadModelos(tipo: string, item: any): object {
        return {
            tipoVeiculo: tipo,
            identificadorMarcaVeiculo: item
        };
    }

    payloadCreditoPlaca(cpf: string, placa: string): object {
        return {
            empresa: 'CAIXA',
            cpf: cpf,
            placaVeiculo: placa,
            canalVenda: 10005
        };
    }

    payloadValidarPlaca(cpf: string, placa: string): object {
        return {
            codigoEmpresa: 'CAIXA',
            nrCpfCnpj: cpf,
            placaVeiculo: placa,
            numeroDispositivo: ''
        };
    }

    payloadCep(cep: string): string {
        return cep;
    }

    payloadEndereco(dados: DadosInterface): object {
        return {
            codigoEmpresa: 'CAIXA',
            nrCpfCnpj: dados.usuario?.cpf!.replace(/\D/g, ''),
            nome: dados.usuario?.nome!.toUpperCase(),
            email: dados.usuario?.email,
            telefone: dados.usuario?.telefone!.replace(/\D/g, ''),
            usuario: 'api-varejo-jv',
            endereco: {
                cep: dados.endereco!.cep.replace(/\D/g, ''),
                logradouro: dados.endereco!.logradouro,
                numero: dados.endereco!.numero,
                ...(dados.endereco!.complemento ? { complemento: dados.endereco!.complemento } : {}),
                bairro: dados.endereco!.bairro,
                cidade: dados.endereco!.cidade,
                estado: dados.endereco!.estado.estadoId
            }
        };
    }

    payloadTokeninzing(cartao: string): object {
        return {
            dataToToken: cartao.replace(/\D/g, '')
        };
    }

    payloadAuthZero(dados: DadosInterface): object {
        return {
            numeroCartao: dados.pagamento!.tokenizing,
            mesVencimento: this._utilService.getMesPorId(dados.pagamento!.cartaoCredito!.mesVencimentoCartao!),
            anoVencimento: dados.pagamento?.cartaoCredito!.anoVencimentoCartao,
            nomeCliente: dados.usuario?.nome,
            codigoCliente: dados.usuario?.codigoCliente,
            bandeiraCartao: dados.pagamento?.cartaoCredito?.bandeiraCartao?.bandeiraCartaoNome,
            cpf: dados.usuario?.cpf,
            descricaoSistema: 'CPP_Loterica',
            empresa: "CAIXA"
        };
    }

    payloadRecaptcha(token: string): object {
        return {
            event: {
                token: token,
                siteKey: this._apiService.getRecaptchaChave()
            }
        };
    }

    payloadPedidoCadastrar(dados: DadosInterface): Promise<any> {
        const payload: any = {
            codigoEmpresa: 'CAIXA',
            deviceFingerprintID: dados.deviceFingerprintID,
            pedido: {
                canalEntrada: 8,
                tipo: 1,
                codigoCliente: dados.usuario?.codigoCliente,
                dadosVendedor: {
                    canal: 35,
                    loja: 94,
                    vendedor: 108416,
                    vendedorLoja: 164581,
                    usuario: 'vendedor.jvloterica'
                },
                numeroTelefone: dados.usuario?.telefone!.replace(/\D/g, ''),
                listaItem: [
                    {
                        ofertaComercial: dados.plano?.oferta?.toString(),
                        dadosVeiculo: {
                            idMarca: Number(dados.veiculo?.marca?.marcaId),
                            idModelo: Number(dados.veiculo?.modelo?.modeloId),
                            placa: dados.veiculo?.placa,
                            anoFabricacao: dados.veiculo?.anoFabricacao,
                            categoria: 1,
                            numeroDispositivo: this._pedidoService.getDispositivo(dados.veiculo?.dispositivo!)
                        },
                        promocao: dados.plano?.promocao ?? '',
                        produtoSuplementar: dados.plano?.abastece ? this.getAdditionalProduct(dados?.plano) : '',
                    },
                ],
                emailConta: dados.usuario?.email,
                modalidadePagamento: this._pagamentoService.isPrePago ? 1 : 2,
                tipoEntrega: 3,
                formaPagamento: this._pagamentoService.getFormaPagamentoNumber(),
                dadosPagamento: this._pagamentoService.getDadosPagamento(dados),
                observacao: 'ativação-loterica-jv'
            },
            deviceIP: dados.deviceIP
        };

        if (this._pagamentoService.isPrePago)
            payload.pedido.listaItem[0]['valorDisparo'] = dados.pagamento?.recarga;

        return payload;
    }

    getAdditionalProduct(dados?: PlanoInterface) {

        
        let isPrePago = this._pagamentoService.isPrePago;

        if (isPrePago && this._pagamentoService.getFormaPagamentoNumber() == 1) {
            return "21014";
        } else if(isPrePago && this._pagamentoService.getFormaPagamentoNumber() == 3) {
            return "21014";
        } else if(!isPrePago && this._pagamentoService.getFormaPagamentoNumber() == 1) {
            return "21019";
        } else if(!isPrePago && this._pagamentoService.getFormaPagamentoNumber() == 3) {
            return "21019";
        } else {
            console.log('generics value from product suplementar');
            return "21019";
        }
    }

    payloadPedidoAnalisar(dados: DadosInterface): object {
        return {
            idTransacao: dados.idTransacao,
            deviceFingerprintID: dados.deviceFingerprintID,
            usuario: 'vendedor.jvloterica',
            canalVendaMotor: '10005',
            layoutEmail: 5,
            deviceIP: dados.deviceIP
        };
    }

    payloadPedidoAtribuicao(dados: DadosInterface): object {
        return {
            idTransacao: dados.idTransacao,
        };
    }

    payloadPostCep(dados: DadosInterface): object {
        const regex = /[^a-zA-Z0-9]/g;

        const payload: any = {
            cpf: dados.usuario?.cpf!.replace(regex, ''),
            placaVeiculo: dados.veiculo?.placa,
            cep: dados.endereco?.cep.replace('-', ''),
            canalVenda: 10005,
            ofertaComercial: dados.plano?.oferta?.toString(),
            nomeInformado: dados.usuario?.nome,
            email: dados.usuario?.email,
            celular: dados.usuario?.telefone?.replace(regex, ''),
            lojaPedido: 94,
            vendedor: 'vendedor.jvloterica',
            numeroPedido: dados.pedido?.numeroPedido,
            formaPagamento: this._pagamentoService.getFormaPagamentoDiscritivo(),
            pagamento: {}
        };

        if (dados.pagamento?.formaPagamento === FormaPagamentoEnum.CARTAO_CREDITO) {
            const validade = `${this._utilService.getMesPorId(dados.pagamento!.cartaoCredito!.mesVencimentoCartao!)}/${dados.pagamento?.cartaoCredito!.anoVencimentoCartao}`

            payload.cartaoCredito = {
                cartao: dados.pagamento?.cartaoCredito?.numeroCartao?.replaceAll(' ', ''),
                validade: validade
            };
        } else {
            payload.debito = {
                banco: Number(dados.pagamento?.banco?.bancoId),
                agencia: Number(dados.pagamento?.banco?.agencia),
                conta: Number(dados.pagamento?.banco?.conta)
            };
        }

        return payload;
    }
}