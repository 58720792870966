import { Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from './api.service';
import { UrlService } from './url.service';

@Injectable({
  providedIn: 'root',
})
export class AuthZeroService {
  private _apiService = inject(ApiService);
  private _urlService = inject(UrlService);

  postAuthZero(payload: any): Observable<any> {
    const url = this._urlService.getApiPagamentoAuthZero();

    return this._apiService.post(url, payload);
  }
}
