<section class="flex flex-col justify-center w-full">
  <ftc-nav-ativar></ftc-nav-ativar>

  <div class="m-0 lg:mx-auto">
    <ftc-meus-dados *ngIf="etapa === EtapaEnum.MEUS_DADOS" class="w-full"></ftc-meus-dados>

    <ftc-planos *ngIf="etapa === EtapaEnum.PLANOS" class="w-full"></ftc-planos>

    <ftc-veiculo *ngIf="etapa === EtapaEnum.VEICULO" class="w-full"></ftc-veiculo>

    <ftc-endereco *ngIf="etapa === EtapaEnum.ENDERECO" class="w-full"></ftc-endereco>

    <div *ngIf="etapa === EtapaEnum.PAGAMENTO" class="form">
      <ftc-menu-ativar *ngIf="!_pagamentoService.isPrePago"></ftc-menu-ativar>

      <ftc-valor-recarga *ngIf="_pagamentoService.isPrePago"></ftc-valor-recarga>

      <ftc-cartao-credito *ngIf="_pagamentoService.formaPagamento === FormaPagamentoEnum.CARTAO_CREDITO; else templateDebit"
        (creditOutput)="continuar()"></ftc-cartao-credito>

      <ng-template #templateDebit>
        <ftc-debito-conta *ngIf="_pagamentoService.formaPagamento === FormaPagamentoEnum.DEBITO_CONTA"
          (debitOutput)="continuar()"></ftc-debito-conta></ng-template>
    </div>
  </div>
</section>

<ftc-atencao *ngIf="modalAtencao !== 0" [type]="modalAtencao" (fecharModal)="fecharModal()"></ftc-atencao>



<ftc-mensagem *ngIf="modalMensagem !== 0" [type]="modalMensagem" (fecharModal)="fecharModalFeedback()"></ftc-mensagem>

<ftc-mensagem [message]="messageFeedbackAccount" *ngIf="showAccountFeedback" [type]="7" (fecharModal)="closeModalMessage()"></ftc-mensagem>

<ftc-progresso *ngIf="openModalProgresso"></ftc-progresso>

<ftc-progresso-cont [retry]="retry" *ngIf="openModalProgressoCont"></ftc-progresso-cont>


<ftc-resumo *ngIf="openModalResumo" (fecharModal)="getRecaptcha()"
  (editarModalResumo)="editarModalResumo($event)"></ftc-resumo>