import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'real',
    standalone: true
})
export class RealPipe implements PipeTransform {
    transform(value: number): string {
        return `R$ ${value.toFixed(2).replace('.', ',')}`;
    }
}