import { CommonModule } from '@angular/common';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { enableProdMode, importProvidersFrom } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule, bootstrapApplication } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module } from 'ng-recaptcha';
import { NgxGoogleAnalyticsModule, NgxGoogleAnalyticsRouterModule } from 'ngx-google-analytics';
import { NgxMaskModule } from 'ngx-mask';
import { AppRoutingModule } from './app/app-routing.module';
import { AppComponent } from './app/app.component';
import { AppService } from './app/app.service';
import { environment } from './environments/environment';

if (environment.production) {
    enableProdMode();
}

bootstrapApplication(AppComponent, {
    providers: [
        importProvidersFrom(NgxGoogleAnalyticsModule.forRoot('G-V1R6XR26L5')),
        importProvidersFrom(NgxGoogleAnalyticsRouterModule),
        importProvidersFrom(BrowserModule, FormsModule, ReactiveFormsModule, NgxMaskModule.forRoot({ dropSpecialCharacters: false }), AppRoutingModule, CommonModule, RouterModule, RecaptchaV3Module),
        AppService,
        {
            provide: RECAPTCHA_V3_SITE_KEY,
            useFactory: (_appService: AppService) => _appService.getRecaptchaChave(),
            deps: [AppService],
        },
        provideHttpClient(withInterceptorsFromDi()),
    ]
})
    .catch(err => console.error(err));
