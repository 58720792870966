import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'celular',
    standalone: true,
})
export class CelularPipe implements PipeTransform {
    transform(valor: any): string {
        if (!valor) return valor;
        let numero = valor.replace(/\D/g, "");
        return `(${numero.slice(0, 2)}) ${numero.slice(2, 5)} ${numero.slice(5, 8)} ${numero.slice(8, 11)}`;
    }
}