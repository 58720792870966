import { NgClass, NgIf } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { AppAbstract } from 'src/app/app.abstract';
import { EtapaEnum } from 'src/app/enuns/etapa.enum';
import { ProgressoComponent } from 'src/app/modals/progresso/progresso.component';
import { FormaPagamentoEnum } from '../../enuns/forma-pagamento.enum';
import { AtencaoComponent } from '../../modals/atencao/atencao.component';
import { MensagemComponent } from '../../modals/mensagem/mensagem.component';
import { ResumoComponent } from '../../modals/resumo/resumo.component';
import { DadosInterface } from '../../models/dados.interface';
import { CartaoCreditoComponent } from './cartao-credito/cartao-credito.component';
import { DebitoContaComponent } from './debito-conta/debito-conta.component';
import { EnderecoComponent } from './endereco/endereco.component';
import { MenuAtivarComponent } from './menu-ativar/menu-ativar.component';
import { MeusDadosComponent } from './meus-dados/meus-dados.component';
import { NavAtivarComponent } from './nav-ativar/nav-ativar.component';
import { PlanosComponent } from './planos/planos.component';
import { ValorRecargaComponent } from './valor-recarga/valor-recarga.component';
import { VeiculoComponent } from './veiculo/veiculo.component';

@Component({
  templateUrl: './ativar-page.component.html',
  standalone: true,
  imports: [
    NavAtivarComponent,
    NgIf,
    MeusDadosComponent,
    PlanosComponent,
    VeiculoComponent,
    EnderecoComponent,
    MenuAtivarComponent,
    ValorRecargaComponent,
    CartaoCreditoComponent,
    DebitoContaComponent,
    NgClass,
    AtencaoComponent,
    MensagemComponent,
    ProgressoComponent,
    ResumoComponent,
  ],
})
export class AtivarPageComponent extends AppAbstract implements OnInit {
  dados: DadosInterface = {} as DadosInterface;

  public showAccountFeedback: boolean = false;
  public messageFeedbackAccount: string = "";

  modalAtencao: number = 0;
  modalMensagem: number = 0;

  openModalProgresso: boolean = false;
  openModalResumo: boolean = false;

  EtapaEnum: typeof EtapaEnum = EtapaEnum;
  FormaPagamentoEnum: typeof FormaPagamentoEnum = FormaPagamentoEnum;

  ngOnInit(): void {
    this.dados = this._localStorageService.descriptografarDados();
    this._etapaService.definirEtapa(Number(localStorage.getItem('etapa')) || EtapaEnum.MEUS_DADOS);
    this._pagamentoService.definirPagamento(this.dados.pagamento?.formaPagamento as FormaPagamentoEnum || FormaPagamentoEnum.CARTAO_CREDITO);
  }

  continuar(): void {
    this.fecharModal();

    try {
      this._pagamentoService.setValorRecarga();
      this._ga4Service.ga4AddPaymentInfo(this.dados);
      this.openModalResumo = true;
    } catch (error) {
      this.fecharModal();
      console.error(error);
    }
  }

  protected editarModalResumo(step: number): void {
    this._etapaService.definirEtapa(step);
    this.fecharModal();
  }

  async getRecaptcha(): Promise<void> {
    try {
      this.fecharModal();
      const token = await this._recaptchaService.getRecaptcha();

      token
        ? await this.postRecaptcha(token)
        : this.modalMensagem = 3;
    } catch (error) {
      console.error(error);
      this.modalMensagem = 3;
    }
  }

  private async postRecaptcha(token: string): Promise<void> {
    this.openModalProgresso = true;

    const payload = this._payloadService.payloadRecaptcha(token);
    const resposta = await this._recaptchaService.postRecaptcha(payload).toPromise();

    this._logService.setStorageUltimaApi('recaptcha', null, "");
    await this._logService.postLog();

    this.fecharModal();

    if (this._utilService.isNulo(resposta.tokenProperties)) {
      resposta.tokenProperties.valid
        ? this.postPedidoCadastrar()
        : this.modalMensagem = 3;
    } else {
      this.modalMensagem = 3;
    }
  }

  private async postPedidoCadastrar(): Promise<void> {

   

    try {
      this.openModalProgresso = true;
      this.dados = this._localStorageService.descriptografarDados();
  
   // try for caixa account 
   console.log(`realizando cadastro`)
   
   if(this.dados.pagamento?.banco?.bancoId == "104") {
     console.log('caixa account post finalize');
     console.log(this.dados.pagamento.banco.finalAccount)
     this.dados.pagamento.banco.conta = `${this.dados.pagamento.banco.codigoOperacao}${this.dados.pagamento.banco.finalAccount}`;
   }
  
      const payload = await this._payloadService.payloadPedidoCadastrar(this.dados);
      const resposta = await this._pedidoService.postPedidoCadastrar(payload).toPromise();
  
      this._logService.setStorageUltimaApi('pedido/cadastrar', resposta.status?.codigoRetorno, resposta.status?.mensagem);
      await this._logService.postLog();
  
      const { status, dados } = resposta;
      const mensagem = status.mensagem ?? '';
      const sucesso = 'Pedido Cadastrado com Sucesso';
      const dataPosterior = 'ERR_00644 - A data de validade do cartão deve ser posterior à data atual.';
      const placaExistente = 'Já existe um veículo ativo para o cliente com esta mesma placa';
  
      this.fecharModal();
    
      if (mensagem === placaExistente) {
        this.modalMensagem = 5;
      } else if (mensagem === dataPosterior) {
        this.modalMensagem = 6;
      } else if (mensagem === sucesso) {
        this.criptografarPedidoCadastrar(dados);
      } else if (resposta.status.codigoRetorno == "-1303") {
        this.messageFeedbackAccount = mensagem;
        this.showAccountFeedback = true;
      } else {
        this.modalAtencao = 6;
      }
    } catch(error) {
      console.log('erro de timedout')
      console.error(error);
    }
  }

  private async criptografarPedidoCadastrar(dados: any): Promise<void> {
    this.dados = {
      ...this.dados,
      idTransacao: dados.idTransacao,
      pedido: {
        numeroPedido: dados.numeroPedido,
        statusPedido: dados.statusPedido
      }
    };

    this._localStorageService.criptografarDados(this.dados);
    this._ga4Service.ga4Purchase(this.dados);

    this.postPedidoFinalizar();
  }

  private async postPedidoFinalizar(): Promise<void> {
    this.openModalProgresso = true;

    this.dados = this._localStorageService.descriptografarDados();

    const payload = this._payloadService.payloadPedidoFinalizar(this.dados);
    const resposta = await this._pedidoService.postPedidoFinalizar(payload).toPromise();

    this._logService.setStorageUltimaApi('pedido/finalizar', resposta.status?.codigoRetorno, resposta.status?.mensagem);
    await this._logService.postLog();

    this.fecharModal();

    await this.criptografarPedidoFinalizar(resposta.status.mensagem);

    const mensagem = resposta.status.mensagem ?? '';

    if (mensagem === 'Sucesso') {
      this.modalMensagem = 1;
    } else {
      this.modalAtencao = 6;
    }
  }

  private async criptografarPedidoFinalizar(codigoRetorno: string): Promise<void> {
    this.dados.pedido = {
      ...this.dados.pedido,
      codigoPedidoFinalizar: codigoRetorno
    };

    this._localStorageService.criptografarDados(this.dados);
  }

  private async postPostCep(): Promise<void> {
    this.openModalProgresso = true;

    const payload = this._payloadService.payloadPostCep(this.dados);
    const resposta = await this._cepService.postCreditoCep(payload).toPromise();

    this._logService.setStorageUltimaApi('credito/cep', resposta.status?.codigoRetorno, resposta.resultadoAnaliseCredito?.motivoReprova);
    await this._logService.postLog();

    this.fecharModal();
    this.modalAtencao = 6;
  }

  closeModalMessage() {
    this.showAccountFeedback = false;
  }

  fecharModalFeedback(): void {
    this.modalMensagem === 1
      ? this._router.navigate([''])
      : this.fecharModal();
  }

  fecharModal(): void {
    if (this.modalAtencao === 6) this._router.navigate(['']);
    else {
      this.modalAtencao = this.modalMensagem = 0;
      this.openModalProgresso = this.openModalResumo = false;
    }
  }

  public get etapa(): number {
    return this._etapaService.etapa;
  }

  public get isMobile(): boolean {
    return window.innerWidth < 1024 ? true : false;
  }
}
