<section class="flex flex-col gap-4 mb-8">
  <label>Valor da recarga</label>

  <ul class="flex justify-content w-full gap-2">
    <span class="pill" *ngFor="let item of listaValorDisparo; let i = index"
      [class.pill--active]="valorDisparo === item" (click)="selectValorDisparo(i)">
      {{ item | real }}
    </span>
  </ul>

  <div class="info text-sm">
    Para evitar imprevistos, uma nova recarga será realizada antes que seu saldo acabe, utilizando o cartão de crédito
    escolhido.
  </div>
</section>