import { Component } from '@angular/core';
import { AppAbstract } from 'src/app/app.abstract';

@Component({
  selector: 'ftc-whatsapp-ajuda',
  templateUrl: './whatsapp-ajuda.component.html',
  standalone: true,
})

export class WhatsappAjudaComponent extends AppAbstract { }
