<section class="flex h-screen">
  
  <div class="flex-1">
    <ftc-header>
     
    </ftc-header>
    
    <router-outlet class="h-full"></router-outlet>
    
  </div>
</section>

<ftc-whatsapp-ajuda></ftc-whatsapp-ajuda>


