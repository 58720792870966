import { Component } from '@angular/core';
import { IconSvgComponent } from '../icon-svg/icon-svg.component';
import { WhatsappBtnComponent } from '../whatsapp-btn/whatsapp-btn.component';

@Component({
    selector: 'ftc-footer',
    templateUrl: './footer.component.html',
    standalone: true,
    imports: [IconSvgComponent, WhatsappBtnComponent],
})
export class FooterComponent {}
