import { Component } from '@angular/core';

@Component({
  selector: 'ftc-whatsapp-btn',
  templateUrl: './whatsapp-btn.component.html',
  standalone: true,
})
export class WhatsappBtnComponent {
  protected abrirWhatsapp(): void {
    const url = 'https://api.whatsapp.com/send?phone=551130039590&text=Ol%C3%A1!%20Gostaria%20de%20saber%20mais%20sobre%20a%20tagCAIXA';
    window.open(url, '_blank');
  }
}
