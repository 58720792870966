import { NgFor } from '@angular/common';
import { Component } from '@angular/core';
import { AppAbstract } from 'src/app/app.abstract';
import { DadosInterface } from 'src/app/models/dados.interface';
import { RealPipe } from '../../../pipes/real.pipe';


@Component({
  selector: 'ftc-valor-recarga',
  templateUrl: './valor-recarga.component.html',
  standalone: true,
  imports: [NgFor, RealPipe],
})
export class ValorRecargaComponent extends AppAbstract {
  dados: DadosInterface = {} as DadosInterface;

  valorDisparo: number = 50;
  listaValorDisparo: any[] = [];

  ngOnInit(): void {
    this.dados = this._localStorageService.descriptografarDados();
    this.getValores();
  }

  private async getValores(): Promise<void> {
    const recargas = await this._apiService.get(this._urlService.listaValorRecarga).toPromise();
    this.listaValorDisparo = recargas.recargas; 

    this.descriptografar();
  }

  private descriptografar(): void {
    this.dados = this._localStorageService.descriptografarDados();

    if (this.dados.pagamento?.recarga) {
      const index = this.listaValorDisparo.findIndex(recarga => recarga === this.dados.pagamento?.recarga);
      this.selectValorDisparo(index);
    }
  }

  selectValorDisparo(index: number): void {
    this.valorDisparo = this.listaValorDisparo[index];

    this.criptografarValorRecarga();
  }

  private criptografarValorRecarga(): void {
    this.dados = {
      ...this.dados,
      pagamento: {
        ...this.dados.pagamento,
        recarga: this.valorDisparo,
      }
    };

    this._localStorageService.criptografarDados(this.dados);
  }
}
