import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable, inject } from "@angular/core";
import { firstValueFrom } from "rxjs";
import { DadosInterface } from "../models/dados.interface";
import { ApiService } from "./api.service";
import { LocalStorageService } from "./local-storage.service";
import { PagamentoService } from "./pagamento.service";
import { PayloadService } from "./payload.service";
import { UrlService } from "./url.service";
import { UtilService } from "./util.service";

@Injectable({
    providedIn: 'root'
})
export class LogService {
    private _apiService = inject(ApiService);
    private _httpClient = inject(HttpClient);
    private _localStorageService = inject(LocalStorageService);
    private _pagamentoService = inject(PagamentoService);
    private _payloadService = inject(PayloadService);
    private _urlService = inject(UrlService);
    private _utilService = inject(UtilService);

    private dados: DadosInterface = {} as DadosInterface;

    protected openModalProgresso: boolean = false;

    async postLog(): Promise<void> {
        this.openModalProgresso = true;

        const ultimaApi = JSON.parse(localStorage.getItem('ultima-api') || '{}');

        if (!ultimaApi?.apiKeyLog) await this.getApiKeyLog();

        await this.postLogCbill();

        this.openModalProgresso = false;
    }

    private async getApiKeyLog(): Promise<void> {
        try {
            this.dados = this._localStorageService.descriptografarDados();

            const url = this._urlService.getApiKeyLog();
            const cpf = this.dados.usuario?.cpf!;
            const payload = this._payloadService.payloadApiKeyLog(cpf);
            const resposta = await this._apiService.get(url, payload).toPromise();

            this.setStorageApiKeyLog(resposta.dados?.apiKeyFront);

            await this.getAccessToken();
        } catch (error) {
            console.log("ERROR => ERRO AO GERAR APIKEY DO LOG: " + error);
        }
    }

    private setStorageApiKeyLog(apiKeyLog: string): void {
        let ultimaApi = JSON.parse(localStorage.getItem('ultima-api') || '{}');

        ultimaApi = {
            ...ultimaApi,
            apiKeyLog: apiKeyLog || '',
        };

        localStorage.setItem('ultima-api', JSON.stringify(ultimaApi));
    }

    public async getAccessToken(): Promise<void> {
        try {
            const url = this._urlService.getApiAccessToken();
            const cpf = this.dados.usuario?.cpf!;
            const dispositivo = this.dados.veiculo?.dispositivo!;
            const ultimaApi = JSON.parse(localStorage.getItem('ultima-api') || '{}');

            const headers = new HttpHeaders({
                'Content-Type': 'application/json; charset=UTF-8',
                'x-api-key': ultimaApi.apiKeyLog!
            });

            const payload = this._payloadService.payloadAccessToken(cpf, dispositivo);
            const resposta = await this._httpClient.post(url, payload, { headers }).toPromise() as any;

            if(!resposta.dados.autenticado) {
                alert('Não vai funcionar o log !');
            }
            this.setStorageAccessToken(resposta.dados?.accessToken);
        } catch (error) {
            console.error('ERRO AO GERAR ACCESS TOKEN DO LOG', error);
        }
    }

    private setStorageAccessToken(accessToken: string): void {
        let ultimaApi = JSON.parse(localStorage.getItem('ultima-api') || '{}');

        ultimaApi = {
            ...ultimaApi,
            accessToken: accessToken || '',
        };

        localStorage.setItem('ultima-api', JSON.stringify(ultimaApi));
    }


    async setLogApiVazia(): Promise<void> {
        this.setStorageUltimaApi("", null, "");
        await this.postLog();
    }

    setStorageUltimaApi(nomeApi: string, codigo: number | null, mensagem: string): void {
        let ultimaApi = JSON.parse(localStorage.getItem('ultima-api') || '{}');

        ultimaApi = {
            accessToken: ultimaApi.accessToken || '',
            apiKeyLog: ultimaApi.apiKeyLog || '',
            nomeApi: nomeApi,
            codigo: codigo?.toString(),
            mensagem: mensagem,
        };

        localStorage.setItem('ultima-api', JSON.stringify(ultimaApi));
    }

    private async postLogCbill(): Promise<any> {
        try {
            const url = this._urlService.getApiLogAtivacao();
            const ultimaApi = JSON.parse(localStorage.getItem('ultima-api') || '{}');

            const headers = new HttpHeaders({
                'Content-Type': 'application/json; charset=UTF-8',
                'x-api-key': ultimaApi.apiKeyLog!,
                'accesstoken': ultimaApi.accessToken!,
            });

            this.dados = this._localStorageService.descriptografarDados();

            const endereco = this.dados.endereco;
            const pagamento = this.dados.pagamento;
            const pedido = this.dados.pedido;
            const plano = this.dados.plano;
            const usuario = this.dados.usuario;
            const veiculo = this.dados.veiculo;

            const payload: any = {
                classeStatusPedido: 340,
                codigoAtivacao: veiculo?.dispositivo || veiculo?.dispositivoCliente,
                cpfCnpj: usuario?.cpf,
                dsUltimoRetornoApi: this._apiService.getUltimaApiDados(),
                idCanalDeEntrada: 8,
                observacao: 'ativação-loterica-jv',
                numeroCelular: usuario?.telefone
            };

            if (veiculo?.placa) payload.placa = veiculo.placa;

            if (pedido?.numeroPedido) payload.codigoPedido = pedido.numeroPedido;

            if (pedido?.codigoPedidoFinalizar) payload.statusPedido = this.fomatarCodigoFinalizar(pedido.codigoPedidoFinalizar);

            if (usuario?.nome) payload.nomeCliente = usuario.nome;

            if (veiculo?.marca?.marcaId) payload.idMarcaVeiculo = Number(veiculo.marca.marcaId);

            if (veiculo?.modelo?.modeloId) payload.idModeloVeiculo = Number(veiculo.modelo.modeloId);

            if (usuario?.email) payload.email = usuario.email;

            if (endereco?.cep) payload.cep = endereco.cep.replace(/\D/g, '');

            if (endereco?.logradouro) payload.nomeLogradouro = endereco.logradouro;

            if (endereco?.numero) payload.numeroLogradouro = endereco.numero;

            if (endereco?.complemento) payload.complemento = endereco.complemento;

            if (endereco?.bairro) payload.bairro = endereco.bairro;

            if (endereco?.cidade) payload.cidade = endereco.cidade;

            if (endereco?.estado.estadoId) payload.idUf = endereco.estado.estadoId;

            if (veiculo?.anoFabricacao) payload.anoFabricacao = veiculo.anoFabricacao;

            if (pagamento?.formaPagamento) payload.formaDePagamento = pagamento.formaPagamento;

            if (pagamento?.cartaoCredito) payload.bandeira = pagamento.cartaoCredito?.bandeiraCartao?.bandeiraCartaoId;

            if (pagamento?.tokenizing) payload.numeroCartaoCredito = pagamento.tokenizing;

            if (pagamento?.cartaoCredito?.nomeImpressoCartao) payload.nomeClienteCartao = pagamento.cartaoCredito.nomeImpressoCartao;

            if (pagamento?.cartaoCredito?.mesVencimentoCartao && pagamento.cartaoCredito.anoVencimentoCartao)
                payload.validadeCartao = this._utilService.formatarDataMMYY(this.dados);

            if (plano?.oferta) payload.plano = plano.oferta.toString();

            if (pagamento?.banco?.bancoId) payload.banco = pagamento.banco.bancoId;

            if (pagamento?.diaPagamento) payload.diaPagamento = pagamento.diaPagamento;

            if (pagamento?.banco?.agencia) payload.numeroAgencia = pagamento.banco.agencia;

            if (pagamento?.banco?.conta) payload.numeroConta = pagamento.banco.conta;

            if (pagamento?.banco?.codigoOperacao) payload.idTipoOperacaoConta = pagamento.banco.codigoOperacao;

            if (this._pagamentoService.isPrePago) payload.valorFaixaRecarga = pagamento?.recarga;

            return await firstValueFrom(this._httpClient.post(url, payload, { headers }));
        } catch (error) {
            console.log("ERROR => ERRO AO SALVAR LOG: " + error);
        }
    }

    private fomatarCodigoFinalizar(codigo: string): number {
        if (codigo === "Sucesso") return 0;
        if (codigo === "Pedido Reprovado.") return 1;
        if (codigo === "Pedido Em Análise.") return 2;
        return -1;
    }
}