import { NgClass, NgFor, NgIf } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { AppAbstract } from 'src/app/app.abstract';
import { EtapaEnum } from 'src/app/enuns/etapa.enum';
import { DadosInterface } from 'src/app/models/dados.interface';
import { PlanoInterface } from 'src/app/models/plano.interface';
import { SaibaMaisComponent } from '../../../modals/saiba-mais/saiba-mais.component';

@Component({
  selector: 'ftc-planos',
  templateUrl: './planos.component.html',
  standalone: true,
  imports: [
    NgFor,
    NgClass,
    NgIf,
    SaibaMaisComponent,
  ],
})
export class PlanosComponent extends AppAbstract implements OnInit {
  dados: DadosInterface = {} as DadosInterface;

  isModalMoreViewOpen: boolean = false;

  listaPlanos: PlanoInterface[] = [];
  planoSelecionado: PlanoInterface = {};
  planoSelecionadoSaibaMais: any = {};

  ngOnInit(): void {
    this.dados = this._localStorageService.descriptografarDados();
    this.iniciarListaMes();
  }

  private async iniciarListaMes(): Promise<void> {
    const data: any = await this._apiService.get(this._urlService.listaPlanos).toPromise();
    this.listaPlanos = data.planos as PlanoInterface[];

    this.getPlanoInicial();
  }

  private getPlanoInicial(): void {
    this.planoSelecionado = this.dados?.plano || this.listaPlanos[0];
  }

  protected saibaMais(plano: any): void {
    if (plano) {
      this.isModalMoreViewOpen = true;
      this.planoSelecionadoSaibaMais = plano;
    }
  }

  protected fecharModal(): void {
    this.isModalMoreViewOpen = false;
  }

  protected selectPlano(plano: PlanoInterface): void {
    this.planoSelecionado = plano;
  }

  protected continuar(): void {
    this.criptografarPlano();
    this._logService.setLogApiVazia();
    this._etapaService.definirEtapa(EtapaEnum.VEICULO);
  }

  private criptografarPlano(): void {
    const dados = this._localStorageService.descriptografarDados();

    this.dados = {
      ...dados,
      plano: this.planoSelecionado
    };

    this._localStorageService.criptografarDados(this.dados);
    this._ga4Service.ga4AddToCart(this.dados);
  }
}
