import { NgClass, NgIf } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { NgxMaskModule } from 'ngx-mask';
import { AppAbstract } from 'src/app/app.abstract';
import { EtapaEnum } from 'src/app/enuns/etapa.enum';
import { AtencaoComponent } from 'src/app/modals/atencao/atencao.component';
import { DadosInterface } from 'src/app/models/dados.interface';
import { ProgressoComponent } from '../../../modals/progresso/progresso.component';

@Component({
  selector: 'ftc-meus-dados',
  templateUrl: './meus-dados.component.html',
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    NgClass,
    NgIf,
    NgxMaskModule,
    ProgressoComponent,
    AtencaoComponent,
  ],
})
export class MeusDadosComponent extends AppAbstract implements OnInit {
  dados: DadosInterface = {} as DadosInterface;

  formUsuario!: FormGroup;

  modalAtencao: number = 0;

  openModalProgresso: boolean = false;
  submetido: boolean = false;

  ngOnInit(): void {
    this.dados = this._localStorageService.descriptografarDados();
    this.criarFormulario();
  }

  private criarFormulario(): void {
    this.formUsuario = this._formBuilder.group({
      nome: [this.dados.usuario?.nome || '', Validators.required],
      email: [this.dados.usuario?.email || '', [Validators.required, Validators.email]],
      telefone: [this.dados.usuario?.telefone || '', Validators.required],
    });
  }

  protected async continuar(): Promise<void> {
    this.submetido = true;

    if (this.formUsuario.valid) {
      this.openModalProgresso = true;

      try {
        await this.getEnderecoIp();
      } catch (error) {
        this.modalAtencao = 6;
        this.fecharModal();
      }
    }
  }

  private async getEnderecoIp(): Promise<void> {
    const resposta = await this._apiService.get(this._urlService.ipify).toPromise();

    await this._ga4Service.ga4GenerateLead(this.dados);

    this.criptografarUsuario(resposta.ip);
  }

  private criptografarUsuario(deviceIP: string): void {
    this.dados = {
      ...this.dados,
      deviceIP: deviceIP,
      usuario: {
        cpf: this.dados.usuario?.cpf,
        nome: this.formUsuario.value.nome,
        email: this.formUsuario.value.email,
        telefone: this.dados.usuario?.telefone
      },
    };

    this._localStorageService.criptografarDados(this.dados);
    this._logService.setLogApiVazia();
    this._etapaService.definirEtapa(EtapaEnum.PLANOS);
  }

  protected fecharModal(): void {
    this.openModalProgresso = false;
    this.modalAtencao = 0;
  }

  get f(): { [key: string]: any } {
    return this.formUsuario.controls;
  }
}