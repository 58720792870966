import { NgClass, NgFor } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { AppAbstract } from 'src/app/app.abstract';
import { IconSvgComponent } from '../../../components/icon-svg/icon-svg.component';

@Component({
  selector: 'ftc-onde-utilizar',
  templateUrl: './onde-utilizar.component.html',
  standalone: true,
  imports: [
    NgFor,
    IconSvgComponent,
    NgClass,
  ],
})
export class OndeUtilizarComponent extends AppAbstract implements OnInit {
  listaOndeUtilizar: any[] = [];

  ngOnInit(): void {
    this.getOndeUtilizar();
  }

  private getOndeUtilizar(): void {
    this._apiService
      .get(this._urlService.listaOndeUtilizar)
      .subscribe((data: any) => (this.listaOndeUtilizar = data.ondeUtilizar));
  }

  scrollToElement(): void {
    document.getElementById('ativarDispositivo')?.scrollIntoView({ behavior: 'smooth' });
  }
}
