<div class="modal">
  <div class="modal-content w-[630px] p-8 flex flex-col items-center">
    <div class="mb-6 flex justify-end w-full">
    </div>

    <div class="phone-validation">
      <h2><strong>Validar número de celular</strong></h2>
      <p>Digite abaixo o código enviado para {{phone}}</p>
      <div class="code-inputs">
        <input id="1" type="text" maxlength="1" (input)="moveFocus($event, '2')">
        <input id="2" type="text" maxlength="1" (input)="moveFocus($event, '3')">
        <input id="3" type="text" maxlength="1" (input)="moveFocus($event, '4')">
        <input id="4" type="text" maxlength="1">
  
      </div>
      <p *ngIf="invalidCode" style="margin-top: 25px" class="red-code">Código digitado é inválido</p>
      <p (click)="goSend()" *ngIf="showResendMessage" style="margin-top: 15px; color: blue; padding: 5px; display: inline-block;">
        Não recebeu o código? Reenviar código de verificação
      </p>
      <p *ngIf="!showResendMessage" style="margin-top: 15px;">
        Você pode pedir outro em <a id="timer">{{formattedTime}}</a>
      </p>
      <p (click)="notReceivePin()" *ngIf="notReceive" style="margin-top: 15px; color: rgb(0, 0, 0); padding: 5px; display: inline-block; cursor: pointer;">
        Não recebi / Não possuo acesso ao celular 
      </p>
    </div>

    <button style="margin-top: 15px;" (click)="checkPin()" class="btn__expansivel">Confirmar</button>
  </div>
</div>

<ftc-progresso *ngIf="loadingOn"></ftc-progresso>
