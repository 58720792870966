import { Injectable } from '@angular/core';
import { BandeiraEnum } from '../enuns/banderia.enum';

@Injectable({
  providedIn: 'root',
})
export class CartaoCreditoService {
  getCodigoBandeira(bandeira: string): string {
    switch (bandeira) {
      case 'AMERICAN EXPRESS':
        return BandeiraEnum.AMERICAN_EXPRESS;

      case 'DINERS CLUB':
        return BandeiraEnum.DINERS_CLUB;

      case 'ELO':
        return BandeiraEnum.ELO;

      case 'HIPERCARD':
        return BandeiraEnum.HIPERCARD;

      case 'MASTERCARD':
        return BandeiraEnum.MASTERCARD;

      case 'VISA':
        return BandeiraEnum.VISA;

      default:
        return '0';
    }
  }
}
