<section class="modal">
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none"
        class="cursor-pointer fixed top-10 right-10" (click)="modalfechar()">
        <line x1="1.41421" y1="1.26343" x2="16.2635" y2="16.1127" stroke="white" stroke-width="2"
            stroke-linecap="round" />
        <line x1="1" y1="15.8492" x2="15.8492" y2="0.999972" stroke="white" stroke-width="2" stroke-linecap="round" />
    </svg>

    <video autoplay playsinline controls class="h-[96vh] relative">
        <source [src]="video" type="video/mp4">
    </video>
</section>