import { NgClass, NgFor, NgIf } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { AppAbstract } from 'src/app/app.abstract';

@Component({
  selector: 'ftc-nav-ativar',
  templateUrl: './nav-ativar.component.html',
  standalone: true,
  imports: [NgFor, NgIf, NgClass],
})
export class NavAtivarComponent extends AppAbstract implements OnInit {
  listaNavAtivar: any[] = [];

  ngOnInit(): void {
    this.getNavAtivar();
  }

  private async getNavAtivar(): Promise<void> {
    const data: any = await this._apiService.get('assets/mocks/nav_ativar.json').toPromise();
    this.listaNavAtivar = data.nav_ativar;
  }

  protected getIcon(item: any): string {
    return item.index <= this.etapa ? item.ativo : item.inativo;
  }

  protected get navAtivar(): any {  
    return this.listaNavAtivar.filter(item => item.index === this.etapa)[0];
  }

  protected get etapa(): number {
    return this._etapaService.etapa || 0;
  }
}
