<nav class="ftc-nav-ativar w-full h-14 flex justify-center bg-white px-10 b-bottom">
  <div *ngIf="navAtivar" class="lg:hidden h-full flex justify-center items-center gap-x-4">
    <img class="mx-auto h-6" [src]="navAtivar!.ativo" alt="icone" />

    <span class="txt-primary text-lg font-semibold whitespace-nowrap">{{navAtivar!.text}}</span>
  </div>

  <ul class="hidden lg:flex items-center gap-x-16">
    <li *ngFor="let item of listaNavAtivar" class="lg:w-1/4 flex justify-center gap-x-3 itens-center">
      <img class="mx-0 w-6 h-6" [src]="getIcon(item)" alt="icone" />

      <span class="font-semibold text-lg whitespace-nowrap"
        [ngClass]="item.index <= etapa ? 'txt-primary' : 'txt-inativo'">{{item.text}}</span>
    </li>
  </ul>
</nav>