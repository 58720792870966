import { NgIf } from '@angular/common';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { AppAbstract } from 'src/app/app.abstract';
import { EtapaEnum } from 'src/app/enuns/etapa.enum';
import { FormaPagamentoEnum } from 'src/app/enuns/forma-pagamento.enum';
import { DadosInterface } from 'src/app/models/dados.interface';
import { CelularPipe } from 'src/app/pipes/celular.pipe';
import { RealPipe } from 'src/app/pipes/real.pipe';
import { CapitalizePipe } from '../../pipes/capitalize.pipe';

@Component({
  selector: 'ftc-resumo',
  templateUrl: './resumo.component.html',
  standalone: true,
  imports: [NgIf, CapitalizePipe, RealPipe, CelularPipe],
})
export class ResumoComponent extends AppAbstract implements OnInit {
  @Output() fecharModal = new EventEmitter();
  @Output() editarModalResumo = new EventEmitter();

  EtapaEnum: typeof EtapaEnum = EtapaEnum;
  FormaPagamentoEnum: typeof FormaPagamentoEnum = FormaPagamentoEnum;

  dados: DadosInterface = {} as DadosInterface;

  usuario: string = '';

  ngOnInit(): void {
    this.dados = this._localStorageService.descriptografarDados();
    this.formatUse();
  }

  formatUse(): void {
    var itens: any = this.dados.plano?.itens;

    if (itens.length == 0) this.usuario = '';
    if (itens.length == 1) this.usuario = itens[0];

    const penultima = itens.slice(0, itens.length - 1).join(', ');
    const ultima = itens[itens.length - 1];

    this.usuario = `${penultima} e ${ultima}`;
  }

  protected editarEtapa(value: number): void {
    this.editarModalResumo.emit(value);
  }

  protected modalfechar(): void {
    this.fecharModal.emit();
  }
}
