<form [formGroup]="formEndereco" (ngSubmit)="continuar()" class="form mx-auto">
  <div class="mb-4">
    <label>CEP </label>
    <input type="text" placeholder="Ex. 99999-999" mask="00000-000" name="cep" formControlName="cep"
      [ngClass]="{ 'form--error': submetido && f['cep'].errors }" (keyup)="getEndereco($event)">
    <div class="form__error" *ngIf="submetido && f['cep'].errors">
      <span>Campo obrigatório</span>
    </div>
  </div>

  <div class="mb-4">
    <label>Endereço</label>
    <input type="text" placeholder="Ex.: Rua, avenida, travessa" name="logradouro" formControlName="logradouro"
      [ngClass]="{ 'form--error': submetido && f['logradouro'].errors }">
    <div class="form__error" *ngIf="submetido && f['logradouro'].errors">
      <span>Campo obrigatório</span>
    </div>
  </div>

  <div class="flex gap-x-5">
    <div class="mb-4">
      <label>Número</label>
      <input mask="0*" placeholder="Digite o nº" name="numero" formControlName="numero"
        [ngClass]="{ 'form--error': submetido && f['numero'].errors }">
      <div class="form__error" *ngIf="submetido && f['numero'].errors">
        <span>Campo obrigatório</span>
      </div>
    </div>

    <div class="mb-4">
      <label>Complemento</label>
      <input type="text" placeholder="Ex.: casa 2" name="complemento" formControlName="complemento">
    </div>
  </div>

  <div class="mb-4">
    <label>Bairro</label>
    <input type="text" placeholder="Digite seu bairro" name="bairro" formControlName="bairro"
      [ngClass]="{ 'form--error': submetido && f['bairro'].errors }">
    <div class="form__error" *ngIf="submetido && f['bairro'].errors">
      <span>Campo obrigatório</span>
    </div>
  </div>

  <div class="flex gap-x-5">
    <div class="mb-4 w-2/5">
      <label>Estado</label>
      <select name="estado" formControlName="estado" [ngClass]="{ 'form--error': submetido && f['estado'].errors }">
        <option value="">Selecione</option>
        <option *ngFor="let item of listaEstados" [value]="item.value">{{item.text}}</option>
      </select>
      <div class="form__error" *ngIf="submetido && f['estado'].errors">
        <span>Campo obrigatório</span>
      </div>
    </div>

    <div class="mb-4 w-full">
      <label>Cidade</label>
      <input type="text" placeholder="Digite sua cidade" name="cidade" formControlName="cidade"
        [ngClass]="{ 'form--error': submetido && f['cidade'].errors }">
      <div class="form__error" *ngIf="submetido && f['cidade'].errors">
        <span>Campo obrigatório</span>
      </div>
    </div>
  </div>

  <div class="text-center">
    <button>Continuar</button>
  </div>
</form>

<ftc-progresso *ngIf="openModalProgresso"></ftc-progresso>

<ftc-atencao *ngIf="modalAtencao !== 0" [type]="modalAtencao" (fecharModal)="fecharModal()"></ftc-atencao>

<ftc-mensagem *ngIf="modalMensagem !== 0" [type]="modalMensagem" (fecharModal)="fecharModal()"></ftc-mensagem>
