import { Injectable, inject } from "@angular/core";
import { DadosInterface } from "../models/dados.interface";
import { UltimaApiInterface } from '../models/ultima-api.interface';
import { ApiService } from "./api.service";
import { PagamentoService } from "./pagamento.service";

declare const gtag: Function;

@Injectable({ providedIn: "root" })
export class GoogleAnalyticService {
    private _apiService = inject(ApiService);
    private _pagamentoService = inject(PagamentoService);

    ultimaApi: UltimaApiInterface = JSON.parse(localStorage.getItem("ultima-api") ?? '{}');

    ga4SelectContent(contentType: string, contentId: string) {
        gtag("event", "select_content", {
            content_type: contentType,
            content_id: contentId,
            page_location: window.location.href
        });
    }

    ga4StartActivation(resposta: string) {
        gtag("event", "start_activation", {
            page_location: window.location.href,
            resposta_api: resposta
        });
    }

    ga4AddToCart(dados: DadosInterface) {
        gtag("event", "add_to_cart", {
            currency: "BRL",
            value: dados.plano?.preco,
            items: [{
                item_id: dados.plano?.oferta?.toString(),
                item_name: dados.plano?.nome?.toString(),
                item_category: this._pagamentoService.isRecarga(dados.plano?.tipoPagamento!),
                page_location: window.location.href
            }]
        });
    }

    ga4GenerateLead(dados: DadosInterface) {
        gtag("event", "generate_lead", {
            currency: "BRL",
            value: dados.plano?.preco,
            items: [{
                item_id: dados.plano?.oferta?.toString(),
                item_name: dados.plano?.nome?.toString(),
                item_category: this._pagamentoService.isRecarga(dados.plano?.tipoPagamento!),
                page_location: window.location.href
            }]
        });
    }

    ga4VehicleInfo(dados: DadosInterface) {
        gtag("event", "vehicle_info", {
            currency: "BRL",
            value: dados.plano?.preco,
            resposta_api: this._apiService.getUltimaApiMensagem(),
            items: [{
                item_id: dados.plano?.oferta?.toString(),
                item_name: dados.plano?.nome?.toString(),
                item_category: this._pagamentoService.isRecarga(dados.plano?.tipoPagamento!),
                page_location: window.location.href,
            }]
        });
    }

    ga4AddShippingInfo(dados: DadosInterface) {
        gtag("event", "add_shipping_info", {
            currency: "BRL",
            value: dados.plano?.preco,
            items: [{
                item_id: dados.plano?.oferta?.toString(),
                item_name: dados.plano?.nome?.toString(),
                item_category: this._pagamentoService.isRecarga(dados.plano?.tipoPagamento!),
                page_location: window.location.href
            }]
        });
    }

    ga4AddPaymentInfo(dados: DadosInterface) {
        gtag("event", "add_payment_info", {
            currency: "BRL",
            value: dados.plano?.preco,
            resposta_api: this._apiService.getUltimaApiMensagem(),
            items: [{
                item_id: dados.plano?.oferta?.toString(),
                item_name: dados.plano?.nome?.toString(),
                item_category: this._pagamentoService.isRecarga(dados.plano?.tipoPagamento!),
                page_location: window.location.href,
                payment_type: this._pagamentoService.formaPagamentoDescritivo,
                coupon: dados.pagamento?.recarga || "",
            }]
        });
    }

    ga4Purchase(dados: DadosInterface) {
        gtag("event", "purchase", {
            currency: "BRL",
            value: dados.plano?.preco,
            resposta_api: this._apiService.getUltimaApiMensagem(),
            transaction_id: this.ultimaApi?.codigo || "",
            items: [{
                item_id: dados.plano?.oferta?.toString(),
                item_name: dados.plano?.nome?.toString(),
                item_category: this._pagamentoService.isRecarga(dados.plano?.tipoPagamento!),
                page_location: window.location.href,
                payment_type: this._pagamentoService.formaPagamentoDescritivo,
                coupon: dados.pagamento?.recarga || ""
            }]
        });
    }
}
