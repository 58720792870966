import { NgFor, NgIf } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AppAbstract } from 'src/app/app.abstract';
import { IconSvgComponent } from '../../components/icon-svg/icon-svg.component';

@Component({
  selector: 'ftc-saiba-mais',
  templateUrl: './saiba-mais.component.html',
  standalone: true,
  imports: [
    IconSvgComponent,
    NgFor,
    NgIf,
  ],
})
export class SaibaMaisComponent extends AppAbstract {
  @Input() data: any = {};
  @Input() botoes?: boolean = false;

  @Output() fecharModal = new EventEmitter();

  protected modalfechar(): void {
    this.fecharModal.emit();
  }
}
