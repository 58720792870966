import { NgClass, NgFor, NgIf } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { NgxMaskModule } from 'ngx-mask';
import { AppAbstract } from 'src/app/app.abstract';
import { EtapaEnum } from 'src/app/enuns/etapa.enum';
import { AtencaoComponent } from 'src/app/modals/atencao/atencao.component';
import { DadosInterface } from 'src/app/models/dados.interface';
import { ProgressoComponent } from '../../../modals/progresso/progresso.component';
import { TimeoutError } from 'rxjs';
import { MensagemComponent } from 'src/app/modals/mensagem/mensagem.component';

@Component({
  selector: 'ftc-endereco',
  templateUrl: './endereco.component.html',
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    NgxMaskModule,
    NgClass,
    NgIf,
    NgFor,
    ProgressoComponent,
    AtencaoComponent,
    MensagemComponent
  ],
})
export class EnderecoComponent extends AppAbstract implements OnInit {
  private dados: DadosInterface = {} as DadosInterface;

  protected formEndereco!: FormGroup;

  protected openModalProgresso: boolean = false;
  protected submetido: boolean = false;

  protected listaEstados: any[] = [];

  protected modalAtencao: number = 0;

  protected modalMensagem: number = 0;

  async ngOnInit() {
    this.dados = this._localStorageService.descriptografarDados();
    this.criarFormulario();
  }

  private criarFormulario(): void {
    this.formEndereco = this._formBuilder.group({
      cep: [this.dados.endereco?.cep || '', Validators.required],
      logradouro: [this.dados.endereco?.logradouro || '', Validators.required],
      numero: [this.dados.endereco?.numero || '', Validators.required],
      complemento: [this.dados.endereco?.complemento || ''],
      bairro: [this.dados.endereco?.bairro || '', Validators.required],
      estado: [this.dados.endereco?.estado.estadoId || '', Validators.required],
      cidade: [this.dados.endereco?.cidade || '', Validators.required],
    });

    this.getListaEstados();
  }

  private getListaEstados(): void {
    this._subSink.sink = this._apiService.get(this._urlService.listaEstados).subscribe((data: any) => {
      this.listaEstados = data.estados;
    });
  }

  protected async getEndereco(value: any): Promise<void> {
    try {
      if (value.target.value.length === 9 && value.target.value !== '') {
        this.openModalProgresso = true;
        const cep = value.target.value.replace('-', '');
        const payload = this._payloadService.payloadCep(cep);
        const resposta = await this._enderecoService.getEndereco(payload).toPromise();

        if(resposta.erro) {
          this.openModalProgresso = false;
          this.modalMensagem = 10;
        }


        this._logService.setStorageUltimaApi('via/cpf', null, "");
        await this._logService.postLog();

        const estadoNumero = await this._enderecoService.getEstadoNumeroArray(
          this.listaEstados,
          resposta.uf
        );

        this.formEndereco.patchValue({
          logradouro: resposta.logradouro,
          bairro: resposta.bairro,
          estado: estadoNumero,
          cidade: resposta.localidade,
        });

        this.openModalProgresso = false;
      }
    } catch (error) {
      console.log(error);
    }
  }

  protected continuar(): void {
    this.submetido = true;

    if (this.formEndereco.valid) {
      this.openModalProgresso = true;

      try {
        this.formatarEndereco();
      } catch (error) {
        this.fecharModal();
        this.modalAtencao = 12;
      }
    }
  }

  private formatarEndereco(): void {
    this.dados = {
      ...this.dados,
      endereco: {
        cep: this.formEndereco.value.cep,
        logradouro: this.formEndereco.value.logradouro,
        numero: this.formEndereco.value.numero,
        complemento: this.formEndereco.value.complemento,
        bairro: this.formEndereco.value.bairro,
        estado: {
          estadoNome: this._enderecoService.getEstadoTextoArray(
            this.listaEstados,
            this.formEndereco.value.estado
          ),
          estadoId: this.formEndereco.value.estado,
        },
        cidade: this.formEndereco.value.cidade,
      },
    };

    this.postClienteCadastrar();
  }

  private async postClienteCadastrar(): Promise<void> {
    try {
      const payload = this._payloadService.payloadEndereco(this.dados);
      const resposta = await this._clienteService.postClienteCadastrar(payload).toPromise();
  
      this._logService.setStorageUltimaApi('cliente/cadastrar', resposta.status?.codigoRetorno, resposta.status?.mensagem);
  
      this.dados.usuario = {
        ...this.dados.usuario,
        codigoCliente: resposta.dados?.codigoCliente
      }
  
      this.criptografarEndereco();
    } catch(error: any) {
      if (error.status == 504) {
        this.openModalProgresso = false;
        console.log('erro de timedout')
        console.error(error);
        this.modalAtencao = 9;
      }
    }
  }

  private async criptografarEndereco(): Promise<void> {
    this._localStorageService.criptografarDados(this.dados);
    await this._logService.postLog();
    this._ga4Service.ga4AddShippingInfo(this.dados);
    this.fecharModal();
    this._etapaService.definirEtapa(EtapaEnum.PAGAMENTO);
  }

  protected fecharModal(): void {
    this.modalAtencao = 0;
    this.modalMensagem = 0;
    this.openModalProgresso = false;
  }

  get f(): { [key: string]: any } {
    return this.formEndereco.controls;
  }
}
