import { Component, OnInit } from '@angular/core';
import { FooterComponent } from '../../components/footer/footer.component';
import { PlanosComponent } from '../ativar/planos/planos.component';
import { AtivarDispositivoComponent } from './ativar-dispositivo/ativar-dispositivo.component';
import { FicouDuvidaComponent } from './ficou-duvida/ficou-duvida.component';
import { OndeUtilizarComponent } from './onde-utilizar/onde-utilizar.component';
import { VejaFacilComponent } from './veja-facil/veja-facil.component';

@Component({
  templateUrl: './inicio-page.component.html',
  standalone: true,
  imports: [
    AtivarDispositivoComponent,
    PlanosComponent,
    OndeUtilizarComponent,
    VejaFacilComponent,
    FicouDuvidaComponent,
    FooterComponent,
  ],
})
export class InicioPageComponent implements OnInit {
  ngOnInit(): void {
    this.cleanLocalStorage();
  }

  protected cleanLocalStorage(): void {
    localStorage.clear();
  }
}
