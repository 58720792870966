<section class="flex justify-center lg:px-8">
  <div class="w-full max-w-[1280px]">
    <div class="lg:hidden flex justify-center items-center my-8">
      <img src="assets/images/onde_utiliza_mobile.png" alt="item.icone" class="w-[80%]">
    </div>

    <div class="hidden lg:flex flex-col items-center gap-4 py-12 lg:py-28 bg-secondary rounded-3xl">
      <p class="text-[32px] txt-text font-bold text-center w-full">Onde utilizar </p>

      <p class="text-xl lg:text-2xl text-center w-9/12">
        Além da cobertura em todos os pedágios do Brasil, você também pode utilizar em estacionamentos, postos de
        gasolina, lava-rápidos e drive-thrus.
      </p>

      <div class="mx-auto grid grid-cols-5 gap-8 my-12 overflow-x-auto">
        <li *ngFor="let item of listaOndeUtilizar" class="max-w-[180px] flex flex-col items-center gap-2">
          <img [src]="item.icone" alt="item.icone" class="w-[90px] rounded-full bg-white">

          <p class="txt-text text-lg font-bold">{{ item.titulo }}</p>

          <p class="text-base text-center">{{ item.texto }}</p>
        </li>
      </div>

      <div class="text-center">
        <button (click)="scrollToElement(); _ga4Service.ga4SelectContent('click_cta', 'escolha_seu_plano')">Ativar agora
          sua tagCAIXA </button>
      </div>
    </div>
  </div>
</section>