import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { AppAbstract } from './app.abstract';

@Injectable({
    providedIn: 'root'
})
export class AuthGuard extends AppAbstract implements CanActivate {
    canActivate(): boolean {
        if (this._localStorageService.hasUsuarioValido()) {
            return true;
        } else {
            this._router.navigate(['']);
            return false;
        }
    }
}