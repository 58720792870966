<section id="ativarDispositivo" class="flex justify-center bg-secondary py-12 lg:px-10">

  <div class="w-full max-w-[1024px] flex flex-col lg:flex-row justify-center lg:justify-between">

    <div class="flex flex-col justify-center space-between items-center lg:items-start mb-8">
      <h1 class="txt-text text-4xl lg:text-[42px] font-bold mb-4">Ative sua tag​</h1>
      <p class="txt-text text-xl lg:text-2xl text-center">Ative agora sua tagCAIXA comprada na lotérica.</p>
    </div>

    <form [formGroup]="formAtivar" (ngSubmit)="ativarAgora()" class="form mx-0 shadow-xl rounded-xl">
      <div class="mb-4">
        <label>Celular com DDD</label>
        <input type="text" mask="(00) 00000-0000" placeholder="(00) 00000-0000" name="telefone"
          formControlName="telefone" [ngClass]="{ 'form--error': submetido && f['telefone'].errors }">

        <div *ngIf="submetido && f['telefone'].errors" class="form__error">
          <span *ngIf="f['telefone'].errors['invalidEmpty']"> Campo obrigatório </span>
          <span *ngIf="f['telefone'].errors['invalidAmount']"> Deve ter 11 dígitos </span>
          <span *ngIf="f['telefone'].errors['invalidFormat']"> Formato inválido </span>
        </div>
      </div>

      <div class="mb-4">
        <label>CPF</label>
        <input type="text" mask="000.000.000-00" id="cpf" formControlName="cpf" placeholder="000.000.000-00"
          [ngClass]="{ 'form--error': submetido && f['cpf'].errors }" />

        <div *ngIf="submetido && f['cpf'].errors" class="form__error">
          <span *ngIf="f['cpf'].errors['invalidEmpty']"> Campo obrigatório </span>
          <span *ngIf="f['cpf'].errors['invalidFormat']"> Formato inválido </span>
        </div>
      </div>

      <div class="mb-4">
        <label class="mt-1">Número de ativação</label>
        <input type="text" id="dispositivo" formControlName="dispositivo" [mask]="mascaraDispositivo"
          placeholder="07 ou 1049" [ngClass]="{ 'form--error': submetido && f['dispositivo'].errors }" />

        <div *ngIf="submetido && f['dispositivo'].errors" class="form__error">
          <span *ngIf="f['dispositivo'].errors['invalidEmpty']">Campo obrigatório</span>
          <span *ngIf="f['dispositivo'].errors['invalidFormat']">Formato inválido</span>
          <span *ngIf="f['dispositivo'].errors['invalidPattern']">Digite apenas números</span>
          <span *ngIf="f['dispositivo'].errors['invalidPrefix']">Deve começar com '07' ou '1049'</span>
          <span *ngIf="f['dispositivo'].errors['invalidPrefix11']">Deve ter 11 dígitos</span>
          <span *ngIf="f['dispositivo'].errors['invalidPrefix15']">Deve ter 15 dígitos</span>
        </div>
      </div>

      <div class="flex justify-between items-center gap-4 ">
        <a class="w-1/2" (click)="openOndeEcontroNumero()" class="txt-info cursor-pointer">
          Onde encontro o número da tag?
        </a>

        <button class="w-[154px]">
          Ativar agora
        </button>
      </div>
    </form>
  </div>
</section>

<ftc-atencao *ngIf="modalAtencao !== 0" [type]="modalAtencao" (fecharModal)="fecharModal()"></ftc-atencao>

<ftc-imagem *ngIf="openModalImage" [image]="codigoOperacaoImagem" [text]="'Localize o número de ativação'"
  (fecharModal)="fecharModal()"></ftc-imagem>

<ftc-progresso *ngIf="openModalProgresso"></ftc-progresso>