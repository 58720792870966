import { Injectable, ViewChild, inject } from '@angular/core';
import { LocalStorageService } from './local-storage.service';
import { UrlService } from './url.service';

@Injectable({
  providedIn: 'root',
})
export class CyberSourceService {
  @ViewChild('#script_cyber_header') cyberHeader: any;
  @ViewChild('#script_cyber_body') cyberBody: any;

  private _urlService = inject(UrlService);
  private _localStorageService = inject(LocalStorageService);

  adicionarCyberSourceScripts(): void {
    this.removerCyberSouceScripts();

    const checkout = this._localStorageService.descriptografarDados();
    const documentId = checkout.veiculo.device;

    const headerScript = document.createElement('script');
    headerScript.id = 'script_cyber_header';
    headerScript.type = 'text/javascript';
    headerScript.src = this._urlService.getUrlCyberSourceHeader(documentId);
    document.head.appendChild(headerScript);

    const bodyScript = document.createElement('noscript');
    bodyScript.id = 'script_cyber_body';
    document.body.appendChild(bodyScript);

    const iframe = document.createElement('iframe');
    iframe.setAttribute(
      'style',
      'width: 100px; height: 100px; border: 0; position:absolute; top: -5000px;'
    );
    iframe.src = this._urlService.getUrlCyberSourceIframe(documentId);

    const cyberIframe = document.getElementById('script_cyber_body') as HTMLDivElement;

    cyberIframe.appendChild(iframe);
  }

  removerCyberSouceScripts(): void {
    const cyberHeader = document.getElementById('script_cyber_header') as HTMLDivElement;
    const cyberBody = document.getElementById('script_cyber_body') as HTMLDivElement;

    if (cyberHeader && cyberBody) {
      cyberHeader.remove();
      cyberBody.remove();
    }
  }
}
