import { Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from './api.service';
import { UrlService } from './url.service';

@Injectable({
  providedIn: 'root',
})
export class DispositivoService {
  private _apiService = inject(ApiService);
  private _urlService = inject(UrlService);

  validarDispositivo(control: any): any {
    const device = control.value;
    const length = device.length;

    if (length <= 0) {
      return { invalidEmpty: true };
    }

    if (length !== 11 && length !== 15) {
      return { invalidFormat: true };
    }

    if (!/^[0-9]+$/.test(device)) {
      return { invalidPattern: true };
    }

    if (length === 11 && !device.startsWith('07')) {
      return { invalidPrefix: true };
    }

    if (length === 15 && !device.startsWith('1049')) {
      return { invalidPrefix: true };
    }

    return null;
  }

  postDispositivoObter(payload: any): Observable<any> {
    const url = this._urlService.getApiDispositivoObter();

    return this._apiService.post(url, payload);
  }
}
