import { Component } from '@angular/core';

@Component({
  selector: 'ftc-progresso',
  template: `
    <div class="modal">
      <div class=" h-[280px] flex flex-col justify-center items-center modal-content px-12 gap-4">
      <img
          width="40"
          height="40"
          src="assets/icons/progresso.svg"
          class="mx-auto progresso-rotate"
        />

        <p class="text-2xl mb-2.5 text-center txt-grafite font-semibold">
        Estamos processando a ativação da sua tagCAIXA
        </p>

        <p class="text-center">Por favor, aguarde.</p>
      </div>
    </div>
  `,
  standalone: true,
})
export class ProgressoComponent { }
