<footer class="flex justify-center items-start bd-top py-14">
  <div class="w-full max-w-[1280px] flex flex-col lg:flex-row justify-between gap-8 px-8 md:px-0">
    <p class="text-xl lg:text-lg"><b>3003 9590</b><br>(Capitais e regiões metropolitanas)</p>

    <p class="text-xl lg:text-lg"><b>0800 721 9590</b><br>(Demais localidades)</p>

    <div>
      <ftc-whatsapp-btn></ftc-whatsapp-btn>

      <p>Segunda à sexta-feira das 9h às 18h<br>Sábado das 9h às 15h</p>
    </div>

    <ftc-icon-svg [icon]="'logo-caixa-footer'" [height]="64"></ftc-icon-svg>
  </div>
</footer>