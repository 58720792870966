import { Injectable } from '@angular/core';
import { EtapaEnum } from '../enuns/etapa.enum';

@Injectable({
  providedIn: 'root',
})
export class EtapaService {
  etapa: EtapaEnum = EtapaEnum.MEUS_DADOS;

  definirEtapa(value: EtapaEnum): void {
    this.etapa = value;
    localStorage.setItem('etapa', this.etapa.toString());
  }
}
