<div class="modal">
  <div class="modal-content p-8 bg-white w-[690px] flex flex-col gap-y-4">
    <p class="text-xl font-semibold">
      Por favor, confirme os seus dados antes de prosseguir:
    </p>

    <div class="bg-resume flex-col p-4 b-radius">
      <div class="flex justify-between">
        <p class="txt-title text-lg">Meus Dados</p>
        <a (click)="editarEtapa(0)" class="txt-link pt-1.5 cursor-pointer">Editar</a>
      </div>
      <p class="mt-2"><b>Nome completo:</b> {{ dados.usuario!.nome }}</p>
      <p class="mt-2"><b>E-mail:</b> {{ dados.usuario!.email }}</p>
      <p class="mt-2"><b>Celular:</b> {{ dados.usuario!.telefone | celular }}</p>
    </div>

    <div class="bg-resume flex-col p-4 b-radius">
      <div class="flex justify-between">
        <p class="txt-title text-lg">Plano</p>
        <a (click)="editarEtapa(1)" class="txt-link pt-1.5 cursor-pointer"> Editar </a>
      </div>
      <p class="mt-2"><b>Plano escolhido:</b> {{ dados.plano!.nome }}</p>
      <p class="mt-2"><b>Usos do plano:</b> {{ usuario }}</p>
      <p class="mt-2"><b>Mensalidade:</b> R$ {{ dados.plano!.preco }}</p>
    </div>

    <div class="bg-resume flex-col p-4 b-radius">
      <div class="flex justify-between">
        <p class="txt-title text-lg">Veículo</p>
        <a (click)="editarEtapa(2)" class="txt-link pt-1.5 cursor-pointer">Editar</a>
      </div>
      <p class="mt-2">
        <b>Marca:</b> {{ dados.veiculo!.marca!.marcaNome | capitalize }}
      </p>
      <p class="mt-2">
        <b>Modelo:</b> {{ dados.veiculo!.modelo!.modeloNome | capitalize }}
      </p>
      <p class="mt-2"><b>Ano:</b> {{ dados.veiculo!.anoFabricacao }}</p>
      <p class="mt-2"><b>Placa:</b> {{ dados.veiculo!.placa }}</p>
      <p class="mt-2"><b>Número da tag:</b> {{ dados.veiculo!.dispositivoCliente }}</p>
    </div>

    <div class="bg-resume flex-col p-4 b-radius">
      <div class="flex justify-between">
        <p class="txt-title text-lg">Endereço</p>
        <a (click)="editarEtapa(3)" class="txt-link pt-1.5 cursor-pointer">Editar</a>
      </div>
      <p class="mt-2"><b>CEP:</b> {{ dados.endereco!.cep }}</p>
      <p class="mt-2">
        <b>Endereço:</b> {{ dados.endereco!.logradouro }},
        {{ dados.endereco!.numero }}
      </p>
      <p *ngIf="dados.endereco!.complemento" class="mt-2">
        <b>Complemento:</b> {{ dados.endereco!.complemento }}
      </p>
      <p class="mt-2"><b>Bairro:</b> {{ dados.endereco!.bairro }}</p>
      <p class="mt-2">
        <b>Localidade:</b> {{ dados.endereco!.cidade }} -
        {{ dados.endereco!.estado.estadoId }}
      </p>
    </div>

    <div *ngIf="_pagamentoService.formaPagamento  === FormaPagamentoEnum.CARTAO_CREDITO; else templatePagamento"
      class="bg-resume flex-col p-4 b-radius">
      <div class="flex justify-between">
        <p class="txt-title text-lg">Pagamento</p>
        <a (click)="editarEtapa(4)" class="txt-link pt-1.5 cursor-pointer">Editar</a>
      </div>
      <p *ngIf="dados.pagamento?.recarga" class="mt-2">
        <b>Valor da Recarga: </b>{{ dados.pagamento?.recarga! | real }}
      </p>
      <p class="mt-2"><b>Forma de pagamento:</b> Cartão de crédito</p>
      <p class="mt-2">
        <b>Nome impresso no cartão:</b> {{ dados.pagamento!.cartaoCredito!.nomeImpressoCartao }}
      </p>
      <p class="mt-2">
        <b>Número do cartão:</b> {{ dados.pagamento!.cartaoCredito!.numeroCartao }}
      </p>
      <p class="mt-2">
        <b>Validade:</b> {{ dados.pagamento!.cartaoCredito!.mesVencimentoCartao }} de
        {{ dados.pagamento!.cartaoCredito!.anoVencimentoCartao }}
      </p>
    </div>

    <ng-template #templatePagamento>
      <div class="bg-resume flex-col p-4 b-radius">
        <div class="flex justify-between">
          <p class="txt-title text-lg">Pagamento</p>
          <a (click)="editarEtapa(4)" class="txt-link pt-1.5 cursor-pointer">Editar</a>
        </div>
        <p class="mt-2"><b>Forma de pagamento:</b> Débito em conta</p>
        <p class="mt-2"><b>Banco:</b> {{ dados.pagamento!.banco!.bancoNome }}</p>
        <p class="mt-2"><b>Agência:</b> {{ dados.pagamento!.banco!.agencia }}</p>
        <p class="mt-2">
          <b>Conta:</b> {{ dados.pagamento!.banco!.conta }}-{{ dados.pagamento!.banco!.digitoConta }}
        </p>
        <p class="mt-2"><b>Dia do pagamento:</b> {{ dados.pagamento!.diaPagamento }}</p>
      </div>
    </ng-template>

    <div class="w-full flex justify-center">
      <button class="w-[328px]" (click)="modalfechar()">
        Meus dados estão corretos
      </button>
    </div>
  </div>
</div>