import { HttpHeaders } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from './api.service';
import { UrlService } from './url.service';

@Injectable({
  providedIn: 'root',
})
export class TokenService {
  private _apiService = inject(ApiService);
  private _urlService = inject(UrlService);

  postToken(payload: any): Observable<any> {
    const url = this._urlService.getApiTokenizing();

    const headers = new HttpHeaders({
      'Content-Type': 'application/json; charset=UTF-8',
      'x-api-key': this._apiService.getTokenizingChave()
    });

    return this._apiService.post(url, payload, headers);
  }
}
