import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable, inject } from "@angular/core";
import { Observable } from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class ApiService {
    private _httpClient = inject(HttpClient);

    private _urlInterna = window.location.origin;

    getAmbiente(): boolean {
        const urlInterna = window.location.origin;

        return urlInterna.includes('qa') || urlInterna.includes('dev') || urlInterna.includes('localhost')
            ? false
            : true;
    }

    getApiChave(): string {
        return this._urlInterna.includes('qa') || this._urlInterna.includes('dev') || this._urlInterna.includes('localhost')
            ? 's84VKot7SA1s2tKLsG96N3Qv8bQvrj7p3SoyInwF'
            : '0CliZsoyYr6eTcZyOKx5A34tOdKf1zAo8KkAoWWe';
    }

    getUrlCyberSourceChave(): string {
        return this._urlInterna.includes('qa') || this._urlInterna.includes('dev') || this._urlInterna.includes('localhost')
            ? 'k8vif92e'
            : '1snn5n9w';
    }

    getRecaptchaChave(): string {
        return this._urlInterna.includes('qa') || this._urlInterna.includes('dev') || this._urlInterna.includes('localhost')
            ? '6LfpUc0oAAAAAFtP10te_6L84DdWT0UfllpQjkIs'
            : '6LeSgxgoAAAAAJdx-ANkuEVCHifuhYNuWHaJKayA';
    }

    getTokenizingChave(): string {
        return this._urlInterna.includes('qa') || this._urlInterna.includes('dev') || this._urlInterna.includes('localhost')
            ? '0df60d79c73cf4afeee2dfda123eec59d235f5ee050cf6d5a1913d9db79bf87f'
            : 'a09ddca902f794091f1ba1f5ba281117749e1bb38242f80f12ff3ac75dd966ec';
    }

    get(url: string, payload?: any): Observable<any> {
        const params = payload ?? {};
        return this._httpClient.get(url, params);
    }

    post(url: string, payload: any, headers?: HttpHeaders): Observable<any> {
        const defaultHeaders = new HttpHeaders({
            'Content-Type': 'application/json; charset=UTF-8',
            'x-api-key': this.getApiChave(),
        });

        const actualHeaders = headers || defaultHeaders;

        return this._httpClient.post(url, payload, { headers: actualHeaders });
    }

    getUltimaApiDados(): string {
        const ultimaApi = JSON.parse(localStorage.getItem('ultima-api') || '{}');

        return "API: " + (ultimaApi.nomeApi || "-")
            + ", CODIGO: " + (ultimaApi.codigo || "-")
            + ", MENSAGEM: " + (ultimaApi.mensagem || "-");
    }

    getUltimaApiMensagem(): string {
        const ultimaApi = JSON.parse(localStorage.getItem('ultima-api') || '{}');

        return "MENSAGEM: " + ultimaApi.mensagem || "-";
    }
}