import { Injectable, inject } from '@angular/core';
import { FormaPagamentoEnum } from '../enuns/forma-pagamento.enum';
import { DadosInterface } from '../models/dados.interface';
import { LocalStorageService } from './local-storage.service';
import { UtilService } from './util.service';

@Injectable({
  providedIn: 'root',
})
export class PagamentoService {
  private _localStorageService = inject(LocalStorageService);
  private _utilService = inject(UtilService);

  dados: DadosInterface = {} as DadosInterface;

  formaPagamento: FormaPagamentoEnum = FormaPagamentoEnum.CARTAO_CREDITO;

  definirPagamento(formaPagamento: FormaPagamentoEnum): void {
    this.formaPagamento = formaPagamento;
    this.dados = this._localStorageService.descriptografarDados();

    this.dados = {
      ...this.dados,

      pagamento: {
        ...this.dados.pagamento,
        formaPagamento: this.formaPagamento
      }
    }

    this._localStorageService.criptografarDados(this.dados);
  }

  formatarAnoValidade(control: any): any {
    const current = new Date().getFullYear();
    const ano = control.value.toUpperCase();
    const length = ano.length;

    if (length <= 0) return { invalidEmpty: true };
    if (ano < current) return { invalidYear: true };
    return null;
  }

  validarCartaoCredito(control: any): any {
    const length = control.value.replace(/ /g, '').length;

    if (length <= 0) return { invalidEmpty: true };
    if (length < 15 || length > 16) return { invalidCard: true };
    return null;
  }

  isRecarga(tipoPagamento: string): string {
    return tipoPagamento === 'PREPAGO' ? 'recarga_auto_sim' : 'recarga_auto_nao';
  }
  getFormaPagamentoDiscritivo() {
    const formaPagamento = this.dados.pagamento?.formaPagamento;
    return formaPagamento === '1' ? 'PÓS - CARTÃO DE CRÉDITO' : 'PÓS - DÉBITO EM CONTA';
  }

  getFormaPagamentoNumber() {
    const formaPagamento = this.dados.pagamento?.formaPagamento;
    return formaPagamento === '1' ? 1 : 3;
  }

  getDadosPagamento(dados: DadosInterface) {
    const type = this.dados.pagamento?.formaPagamento;

    return type === FormaPagamentoEnum.CARTAO_CREDITO.toString()
      ? {
        codigoBandeira: dados.pagamento!.cartaoCredito!.bandeiraCartao!.bandeiraCartaoId,
        numeroCartao: dados.pagamento!.tokenizing,
        mesVencimento: this._utilService.getMesPorId(dados.pagamento!.cartaoCredito!.mesVencimentoCartao!),
        anoVencimento: dados.pagamento!.cartaoCredito!.anoVencimentoCartao,
        nomeCliente: dados.pagamento!.cartaoCredito!.nomeImpressoCartao!,
      }
      : {
        codigoBanco: dados.pagamento?.banco?.bancoId,
        numeroAgencia: dados.pagamento?.banco?.agencia,
        numeroConta: dados.pagamento?.banco?.conta,
        digitoConta: dados.pagamento?.banco?.digitoConta,
        diaVencimento: dados.pagamento!.diaPagamento,
      };
  }

  setValorRecarga(): void {
    this.dados = this._localStorageService.descriptografarDados();

    if (!this.isPrePago) return;

    const recarga = this.dados.pagamento?.recarga ? this.dados.pagamento?.recarga : 50;

    this.dados = {
      ...this.dados,
      pagamento: {
        ...this.dados.pagamento,
        recarga: recarga
      }
    };

    this._localStorageService.criptografarDados(this.dados);
  }

  get isPrePago(): boolean {
    const dados = this._localStorageService.descriptografarDados();
    return dados.plano?.tipoPagamento === 'PREPAGO' ? true : false;
  }

  get formaPagamentoDescritivo(): string {
    return this.formaPagamento === "1" ? "Cartão de crédito" : "Débito em conta";
  }
}
