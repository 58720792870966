<form [formGroup]="formVeiculo" (ngSubmit)="continuar()" class="form mx-auto">
  <div class="mb-4">
    <label>Marca</label>
    <select name="marca" formControlName="marca" (change)="getModelos()"
      [ngClass]="{ 'form--error': submetido && f['marca'].errors }">
      <option value="">Selecione a marca do veículo</option>
      <option *ngFor="let item of listaMarcas" [value]="item.identificadorMarcaVeiculo">{{item.descricao}}</option>
    </select>
    <div class="form__error" *ngIf="submetido && f['marca'].errors">
      <span>Campo obrigatório</span>
    </div>
  </div>

  <div class="mb-4">
    <label>Modelo</label>
    <select name="model" formControlName="model" (change)="getListaAnos()"
      [ngClass]="{ 'form--error': submetido && f['model'].errors }">
      <option value="">Selecione o modelo do veículo</option>
      <option *ngFor="let item of listaModelos" [value]="item.identificadorModeloVeiculo">{{item.descricao}}</option>
    </select>
    <div class="form__error" *ngIf="submetido && f['model'].errors">
      <span>Campo obrigatório</span>
    </div>
  </div>

  <div class="flex gap-x-5">
    <div class="mb-4 w-2/3">
      <label>Ano</label>
      <select name="ano" formControlName="ano" [ngClass]="{ 'form--error': submetido && f['ano'].errors }">
        <option value="">Ano do veículo</option>
        <option *ngFor="let item of listaAnosFabricacao" [value]="item">{{item}}</option>
      </select>

      <div class="form__error" *ngIf="submetido && f['ano'].errors">
        <span>Campo obrigatório</span>
      </div>
    </div>

    <div class="mb-4">
      <label>Placa</label>
      <input type="text" placeholder="Placa do veículo" mask="SSS0A00" name="placa" formControlName="placa"
        [ngClass]="{ 'form--error': submetido && f['placa'].errors }">
      <div class="form__error" *ngIf="submetido && f['placa'].errors">
        <span *ngIf="f['placa'].errors['invalidEmpty']">Campo obrigatório</span>
        <span *ngIf="f['placa'].errors['invalidFormat']">Formato inválido</span>
        <span *ngIf="f['placa'].errors['invalidPlate']">Placa inválida</span>
      </div>
    </div>
  </div>

  <div class="mb-4">
    <label>Número da tag</label>
    <input type="text" name="dispositivoCliente" formControlName="dispositivoCliente" readonly>
  </div>

  <div class="text-center">
    <button>Continuar</button>
  </div>
</form>

<ftc-progresso *ngIf="openModalProgresso"></ftc-progresso>

<ftc-mensagem *ngIf="modalMensagem !== 0" [type]="modalMensagem" (fecharModal)="fecharModal()"></ftc-mensagem>

<ftc-atencao *ngIf="modalAtencao !== 0" [type]="modalAtencao" (fecharModal)="fecharModal()"></ftc-atencao>