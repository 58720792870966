<section class="flex justify-center">

  <div class="w-[600px] flex flex-col justify-center items-center px-8 lg:px-0 py-12 lg:py-28">

    <p class="text-[32px] txt-text font-bold mb-10 lg:mb-2">Ficou com alguma dúvida?</p>

    <p class="text-xl lg:text-2xl mb-8">Veja as perguntas abaixo</p>

    <div class="w-full flex flex-col gap-4">
      <li *ngFor="let item of listaDuvidas; let i = index" (click)="expandirDuvida(i)"
        class="bg-secondary px-4 cursor-pointer rounded-lg">

        <div class="flex justify-between items-center h-[42px]">
          <span class="text-lg font-bold">{{ item.pergunta }}</span>

          <svg xmlns="http://www.w3.org/2000/svg" [class.arrow-rotate]="duvida === i" width="15" height="9"
            viewBox="0 0 15 9" fill="none">
            <path
              d="M6.91707 8.76129L0.241468 2.16119C-0.0804901 1.84287 -0.0804901 1.3268 0.241468 1.00852L1.02007 0.238729C1.34148 -0.0790436 1.86239 -0.0796547 2.18456 0.237371L7.50002 5.46807L12.8154 0.237371C13.1376 -0.0796547 13.6585 -0.0790436 13.9799 0.238729L14.7585 1.00852C15.0805 1.32684 15.0805 1.84291 14.7585 2.16119L8.08293 8.76126C7.76101 9.07957 7.23903 9.07957 6.91707 8.76129Z"
              fill="var(--color-success)" />
          </svg>
        </div>

        <p *ngIf="duvida === i" class="text-sm pb-4">{{ item.resposta }}</p>
      </li>
    </div>
  </div>
</section>

<section class="flex lg:hidden">
  <div class="w-full text-center pb-12">
    <p class="text-xl">Ainda com dúvida, veja nosso passo-a-passo de <span class="text-xl txt-primary"
        (click)="toggleModal()"> como desbloquear
        sua tagCAIXA</span></p>
  </div>
</section>

<ftc-video *ngIf="openModalVideo" [video]="_urlService.videoTutorialDesbloquearTag"
  (fecharModal)="toggleModal()"></ftc-video>