<form [formGroup]="formUsuario" (ngSubmit)="continuar()" class="form mx-auto">
  <div class="mb-4">
    <label>Nome completo</label>
    <input type="text" placeholder="Digite seu nome completo" name="nome" formControlName="nome"
      [ngClass]="{ 'form--error': submetido && f['nome'].errors }">
    <div class="form__error" *ngIf="submetido && f['nome'].errors">
      <span>Campo obrigatório</span>
    </div>
  </div>

  <div class="mb-4">
    <label>E-mail</label>
    <input type="text" placeholder="Digite o seu e-mail" name="email" formControlName="email"
      [ngClass]="{ 'form--error': submetido && f['email'].errors }">
    <div *ngIf="submetido && f['email'].errors" class="form__error">
      <span *ngIf="f['email'].errors['required']">Campo obrigatório</span>
      <span *ngIf="f['email'].errors['email']">Email inválido</span>
    </div>
  </div>

  <div class="mb-4">
    <label>Celular</label>
    <input type="text" name="telefone" formControlName="telefone" readonly>
  </div>

  <div class="text-center">
    <button>Continuar</button>
  </div>
</form>

<ftc-progresso *ngIf="openModalProgresso"></ftc-progresso>

<ftc-atencao *ngIf="modalAtencao !== 0" [type]="modalAtencao" (fecharModal)="fecharModal()"></ftc-atencao>