import { Injectable, inject } from '@angular/core';
import * as moment from 'moment-timezone';
import { ClipboardService } from 'ngx-clipboard';
import { DadosInterface } from '../models/dados.interface';

@Injectable({
  providedIn: 'root',
})
export class UtilService {
  private _clipboardService = inject(ClipboardService);

  dados: DadosInterface = {} as DadosInterface;

  isNulo(data: any): boolean {
    return data != '' && data != null && data != undefined ? true : false;
  }

  getMesPorId(value: string): string {
    const monthNames = [
      'janeiro',
      'fevereiro',
      'março',
      'abril',
      'maio',
      'junho',
      'julho',
      'agosto',
      'setembro',
      'outubro',
      'novembro',
      'dezembro',
    ];

    const monthIndex = monthNames.indexOf(value.toLowerCase());

    if (monthIndex === -1) return '';

    return (monthIndex < 9 ? '0' : '') + (monthIndex + 1).toString();
  }

  getId(): string {
    return (Math.random() * 10).toFixed(12).toString().replace('.', '');
  }

  formatarDataMMYY(dados: DadosInterface): string {
    const meses: any = {
      "janeiro": "01",
      "fevereiro": "02",
      "março": "03",
      "abril": "04",
      "maio": "05",
      "junho": "06",
      "julho": "07",
      "agosto": "08",
      "setembro": "09",
      "outubro": "10",
      "novembro": "11",
      "dezembro": "12",
    };

    const mesAbreviado = meses[dados.pagamento!.cartaoCredito!.mesVencimentoCartao!.toLowerCase()];
    const anoAbreviado = dados.pagamento!.cartaoCredito!.anoVencimentoCartao!.slice(-2);

    return `${mesAbreviado}/${anoAbreviado}`;
  }

  removerDuplicados(lista: any[]): any[] {
    return lista.filter((value: any, index: any, self: any) => {
      return self.indexOf(value) === index;
    });
  }

  removerObjetosVazios(lista: any[]): any[] {
    return lista.filter(obj => {
      return !Object.values(obj).every(value => value === '' || value === null || value === undefined);
    });
  }

  ordenarAlfabetica(lista: any[]): any[] {
    if (lista.length === 0) return lista;
    const chave = Object.keys(lista[0])[0];
    return lista.sort((a: any, b: any) => a[chave].localeCompare(b[chave]));
  }

  getHorario(): string {
    let date = new Date();
    return moment.tz(date, "America/Sao_Paulo").format();
  }

  copiarTexto(texto: any): void {
    if (typeof texto === 'object') texto = JSON.stringify(texto);
    this._clipboardService.copy(texto.toString());
  }

  getCodigo(): string {
    let code = '';
    const alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    const numbers = '0123456789';

    for (let i = 0; i < 5; i++) {
      (Math.random() < 0.5)
        ? code += alphabet.charAt(Math.floor(Math.random() * alphabet.length))
        : code += numbers.charAt(Math.floor(Math.random() * numbers.length));
    }

    return code;
  }
}
