<div class="modal">
  <div class="modal-content p-8 bg-white">
    <div class="flex flex-row mb-6 flex justify-between">
      <p class="txt-title">{{ text }}</p>

      <ftc-icon-svg [icon]="'closed'" class="cursor-pointer" (click)="modalfechar()"></ftc-icon-svg>
    </div>

    <img [src]="image" [alt]="text" srcset="" />
  </div>
</div>