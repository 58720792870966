import { NgIf } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IconSvgComponent } from '../../components/icon-svg/icon-svg.component';
import { CodeInputModule } from 'angular-code-input';
import { AppAbstract } from 'src/app/app.abstract';
import { DadosInterface } from 'src/app/models/dados.interface';
import { AtencaoComponent } from '../atencao/atencao.component';
import { ProgressoComponent } from '../progresso/progresso.component';

@Component({
  selector: 'ftc-pin',
  templateUrl: './pin.component.html',
  standalone: true,
  imports: [IconSvgComponent, NgIf, CodeInputModule, AtencaoComponent, ProgressoComponent],
})
export class PinComponent extends AppAbstract implements OnInit {

  public timeResend: number = 0;
  private intervalId: any;
  public dispare: number = 0;
  public loadingOn: boolean = false;
  public isResending: boolean = false;
  public invalidCode: boolean = false;
  public notReceive = false;
  
  public phone: string = "";

  @Input() message: string = "";

  public dados: DadosInterface = {};

  @Input() type: number = 0;

  @Output() confirmPin = new EventEmitter();

  @Output() closePinO = new EventEmitter();

  public get formattedTime(): string {
    const minutes = Math.floor(this.timeResend / 60);
    const seconds = this.timeResend % 60;
    return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
  }
  
  
  public get showResendMessage(): boolean {
    return this.timeResend == 0;
  }




  protected startTimer(): void {
    this.timeResend = 120;
    this.intervalId = setInterval(() => {
      if (this.timeResend > 0) {
      this.timeResend--;
      } else {
      clearInterval(this.intervalId);
      }
    }, 1000);

    setInterval(() => {
      this.notReceive = true;
    }, 6000);
    }


  protected moveFocus(event: any, nextInputId: string): void {
    if (event.target.value.length === event.target.maxLength) {
      const nextInput = document.getElementById(nextInputId) as HTMLInputElement;
      if (nextInput) {
        nextInput.focus();
      }
    }
  }



  ngOnInit(): void { 
  }

  
   public async goSend() {
    
    this.startTimer();
    this.dispare++

    const payload = this._payloadService.payloadPedidoVerificarPinMotor( { idTransacao: this.dados.idTransacao} );
    const resposta = await this._pedidoService.postPedidoVerificarPinMotor(payload).toPromise();

    this._logService.setStorageUltimaApi('pedido/pin', resposta.status.codigoRetorno, resposta.status.mensagem);

    await this._logService.postLog();

  }

  public clearInputs(inputs: string[]) {
    inputs.forEach(item => {
      const nextInput = document.getElementById(item) as HTMLInputElement;
      nextInput.value = '';
    })
  }


  public getPin() {
    const pin1 = (document.getElementById('1') as HTMLInputElement).value;
    const pin2 = (document.getElementById('2') as HTMLInputElement).value;
    const pin3 = (document.getElementById('3') as HTMLInputElement).value;
    const pin4 = (document.getElementById('4') as HTMLInputElement).value;
    return `${pin1}${pin2}${pin3}${pin4}`;
  }

  public async checkPin() {

    this.loadingOn = true;
    

    let pin = this.getPin();
    this.dados = this._localStorageService.descriptografarDados();

    const payload = this._payloadService.payloadPedidoConfirmarPinMotor({idTransacao: this.dados.idTransacao}, pin);
    const resposta = await this._pedidoService.postPedidoConfirmarPinMotor(payload).toPromise();
    
    // this._logService.setStorageUltimaApi('pedido/pin-confirmar', resposta.status.codigoRetorno, resposta.status.mensagem);
    // await this._logService.postLog();

    if(resposta.status.codigoRetorno == 0) {
      this.loadingOn = false;
      this.confirmPin.emit();
    } else {
      this.loadingOn = false;
      this.invalidCode = true;
      this.clearInputs(['1', '2', '3', '4']);
    }
  }

  public async notReceivePin() {
    this.closePinO.emit();
  }


  



}
