<section class="w-full flex flex-col">

  <div class="flex gap-4 overflow-x-auto mx-auto p-8 w-[24.875rem] lg:w-full max-w-[1280px] whitespace-normal">

    <div *ngFor="let plano of listaPlanos" (click)="selectPlano(plano)"
      class="max-w-[300px] min-w-[300px] flex flex-col shadow-xl rounded-lg cursor-pointer">

      <div class="flex justify-between items-center bg-secondary p-4 rounded-t-lg">
        <p class="flex-1 text-lg txt-primary font-semibold text-nowrap">{{plano.tipo}}</p>

        <span class="radio" [class.radio-active]="plano.oferta === planoSelecionado.oferta"></span>
      </div>

      <div class="flex flex-col p-4 gap-2 flex-1">
        <div class="flex justify-between align-middle">
          <p class="txt-grafite text-xl font-bold">{{ plano.nome }}</p>

          <a (click)="saibaMais(plano); _ga4Service.ga4SelectContent('click_cta', plano.nome_tecnico!)"
            class="txt-link pt-1.5 cursor-pointer">
            Saiba mais
          </a>
        </div>

        <p class="txt-primary font-bold mb-4 mensalidade">
          {{ plano.mansalidade || "&nbsp;" }}
        </p>

        <div *ngFor="let beneficio of plano.itens" class="flex items-start mt-2">
          <img alt="icone" class="mx-auto mt-2" src="assets/icons/check.svg" height="8" />
          <p class="w-full ml-2 font-base">{{ beneficio }}</p>
        </div>
      </div>

      <p class="txt-grafite p-4 mt-auto ">
        <b>R$ <span  
          
          class="text-2xl price">{{ plano.preco }}</span></b><span class="text-xs price">{{ plano.frequencia
          }}</span>
      </p>
      <!-- <p style="padding-top: 0;" class="txt-grafite p-4 mt-auto ">
        <span style="font-size: 0.8rem;" class="text-2xl price">{{ plano.beforeValue }}</span>
      </p> -->
    </div>
  </div>

  <div class="text-center">
    <button (click)="continuar()">Continuar</button>
  </div>
</section>

<ftc-saiba-mais *ngIf="isModalMoreViewOpen" [data]="planoSelecionadoSaibaMais"
  (fecharModal)="fecharModal()"></ftc-saiba-mais>