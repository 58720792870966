import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IconSvgComponent } from '../../components/icon-svg/icon-svg.component';

@Component({
  selector: 'ftc-imagem',
  templateUrl: './imagem.component.html',
  standalone: true,
  imports: [IconSvgComponent]
})
export class ImagemComponent {
  @Input() image: String = '';
  @Input() text: String = '';

  @Output() fecharModal = new EventEmitter();

  protected modalfechar(): void {
    this.fecharModal.emit();
  }
}
