import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IconSvgComponent } from 'src/app/components/icon-svg/icon-svg.component';

@Component({
  selector: 'ftc-video',
  templateUrl: './video.component.html',
  standalone: true,
  imports: [IconSvgComponent]
})
export class VideoComponent {
  @Input() video: String = '';
  
  @Output() fecharModal = new EventEmitter();

  protected modalfechar(): void {
    this.fecharModal.emit();
  }
}
