import { Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from './api.service';
import { UrlService } from './url.service';

@Injectable({
  providedIn: 'root',
})
export class ClienteService {
  private _apiService = inject(ApiService);
  private _urlService = inject(UrlService);

  postCreditoCpf(payload: object): Observable<any> {
    const url = this._urlService.getApiCreditoCpf();

    return this._apiService.post(url, payload);
  }

  postClienteCadastrar(payload: any): Observable<any> {
    const url = this._urlService.getApiClienteCadastrar();

    return this._apiService.post(url, payload);
  }

  validarCelular(celular: any): any {
    const numero = celular.value.replace(/\D/g, '');
    const length = numero.length;

    if (length <= 0) {
      return { invalidEmpty: true };
    }

    if (length !== 11) {
      return { invalidAmount: true };
    }

    if (numero[2] !== '9') {
      return { invalidFormat: true };
    }

    if (numero.split('').every((char: any) => char === numero[0])) {
      return { invalidFormat: true };
    }

    return null;
  }

  validarCPF(cpf: any): any {
    const numero = cpf.value.replace(/\D/g, '');
    const length = numero.length;

    if (length <= 0) {
      return { invalidEmpty: true };
    }

    if (length !== 11) {
      return { invalidFormat: true };
    }

    if (numero.split('').every((char: any) => char === numero[0])) {
      return { invalidFormat: true };
    }

    let soma = 0;
    for (let i = 0; i < 9; i++) {
      soma += Number(numero[i]) * (10 - i);
    }
    const resto1 = soma % 11;
    const digito1 = 11 - resto1 > 9 ? 0 : 11 - resto1;

    soma = 0;
    for (let i = 0; i < 10; i++) {
      soma += Number(numero[i]) * (11 - i);
    }
    const resto2 = soma % 11;
    const digito2 = 11 - resto2 > 9 ? 0 : 11 - resto2;

    if (digito1 !== Number(numero[9]) || digito2 !== Number(numero[10])) {
      return { invalidFormat: true };
    };

    return null;
  }
}

