import { NgFor, NgIf } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { AppAbstract } from 'src/app/app.abstract';
import { VideoComponent } from 'src/app/modals/video/video.component';
import { IconSvgComponent } from '../../../components/icon-svg/icon-svg.component';

@Component({
  selector: 'ftc-ficou-duvida',
  templateUrl: './ficou-duvida.component.html',
  standalone: true,
  imports: [
    NgFor,
    IconSvgComponent,
    NgIf,
    VideoComponent
  ],
})
export class FicouDuvidaComponent extends AppAbstract implements OnInit {
  protected openModalVideo: boolean = false;

  protected duvida: number = 0;
  protected listaDuvidas: any[] = [];

  ngOnInit(): void {
    this.getDuvidas();
  }

  private getDuvidas(): void {
    this._apiService
      .get(this._urlService.listaDuvidas)
      .subscribe((data: any) => (this.listaDuvidas = data.duvidas));
  }

  protected expandirDuvida(index: number) {
    this.duvida = index;
  }

  protected toggleModal(): void {
    this.openModalVideo = !this.openModalVideo;
  }
}
