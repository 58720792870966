import { Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { AppAbstract } from './app.abstract';
import { HeaderComponent } from './components/header/header.component';
import { WhatsappAjudaComponent } from './components/whatsapp-ajuda/whatsapp-ajuda.component';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'ftc-root',
    templateUrl: './app.component.html',
    standalone: true,
    imports: [HeaderComponent, RouterOutlet, WhatsappAjudaComponent],
})
export class AppComponent extends AppAbstract { 

    public version = environment.version;

    ngOnInit() {
        console.log(`Versão: ${this.version}`);
    }
}
