import { Injectable, inject } from '@angular/core';
import { ApiService } from './services/api.service';

@Injectable({
  providedIn: 'root',
})
export class AppService {
  private _apiService = inject(ApiService);

  getRecaptchaChave(): string {
    return this._apiService.getRecaptchaChave();
  }
}
