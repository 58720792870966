import { Injectable, inject } from '@angular/core';
import { DadosInterface } from '../models/dados.interface';
import { CriptografiaService } from './criptografia.service';

@Injectable({
  providedIn: 'root',
})
export class LocalStorageService {
  private _encryptionService = inject(CriptografiaService);
  private _urlInterna = window.location.origin;

  protected dados: DadosInterface = {} as DadosInterface;

  descriptografarDados(): any {
    const encryption = localStorage.getItem('dados');
    if (encryption) return this._encryptionService.criptografar(encryption);
    return {};
  }

  criptografarDados(dados: DadosInterface): void {
    var encryption = this._encryptionService.encryptData(dados);
    localStorage.setItem('dados', encryption);

    if (this._urlInterna.includes('qa') || this._urlInterna.includes('dev') || this._urlInterna.includes('localhost')) {
      const dadosJson = JSON.stringify(dados);
      localStorage.setItem('dadosDev', dadosJson);
    }
  }

  hasUsuarioValido(): boolean {
    const dados = this.descriptografarDados();

    if (Object.keys(dados).length === 0) return false;

    return (
      dados.usuario?.telefone !== null &&
      dados.usuario?.cpf !== null &&
      dados?.veiculo?.dispositivoCliente !== null
    );
  }
}
