import { Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from './api.service';
import { UrlService } from './url.service';

@Injectable({
  providedIn: 'root',
})
export class EnderecoService {
  listaEstados: any[] = [];

  private _apiService = inject(ApiService);
  private _urlService = inject(UrlService);

  getEndereco(cep: string): Observable<any> {
    const url = this._urlService.getApiEndereco(cep);

    return this._apiService.get(url);
  }

  getEstadoNumeroArray(_listaEstados: any[], text: string): number {
    const estado = _listaEstados.find((item) => item.text === text);
    return estado.value;
  }

  getEstadoTextoArray(_listaEstados: any[], value: number): string {
    const estado = _listaEstados.find((item) => item.value === value);
    return estado.text;
  }
}
