import { Injectable } from '@angular/core';
import { BancoEnum } from '../enuns/banco.enum';

@Injectable({
  providedIn: 'root',
})
export class DebitoService {

  getBancoNome(value: string): string {
    switch (value) {
      case "001":
        return "Banco do Brasil";

      case "104":
        return "Caixa Economica Federal";

      case "341":
        return "Itaú Unibanco";

      case "033":
        return "Santander";

      default:
        return BancoEnum.NULO;
    }
  }
}
