import { Injectable, inject } from '@angular/core';
import * as CryptoJS from 'crypto-js';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class CriptografiaService {
  protected _apiService = inject(ApiService);

  private secretKey: string = this._apiService.getApiChave();

  encryptData(data: any): string {
    const encryptedData = CryptoJS.AES.encrypt(
      JSON.stringify(data),
      this.secretKey
    ).toString();
    return encryptedData;
  }

  criptografar(encryptedData: string): any {
    const decryptedData = CryptoJS.AES.decrypt(encryptedData, this.secretKey);
    const decryptedString = decryptedData.toString(CryptoJS.enc.Utf8);
    return JSON.parse(decryptedString);
  }
}
