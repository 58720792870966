import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'ftc-icon-svg',
  templateUrl: './icon-svg.component.html',
  standalone: true,
})
export class IconSvgComponent implements OnInit {
  @Input() icon: string = '';
  @Input() height: any = 16;
  @Input() margin: any = 0;

  iconPath: string = '';

  ngOnInit(): void {
    this.height = this.height.toString() + 'px';
    this.margin = this.margin.toString() + 'px';

    if (this.icon) {
      this.iconPath = `assets/icons/${this.icon}.svg`;
    }
  }
}
