import { Component } from '@angular/core';
import { RouterLink } from '@angular/router';
import { IconSvgComponent } from '../icon-svg/icon-svg.component';

@Component({
    selector: 'ftc-header',
    templateUrl: './header.component.html',
    standalone: true,
    imports: [IconSvgComponent, RouterLink],
})
export class HeaderComponent {}
