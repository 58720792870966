import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { Observable } from 'rxjs';
import { UrlService } from './url.service';
import { UtilService } from './util.service';

@Injectable({
  providedIn: 'root',
})
export class RecaptchaService {
  private _httpClient = inject(HttpClient);
  private _recaptchaV3Service = inject(ReCaptchaV3Service);
  private _urlService = inject(UrlService);
  private _utilService = inject(UtilService);

  getRecaptcha() {
    return new Promise<string>((resolve, reject) => {
      this._recaptchaV3Service
        .execute('importantAction')
        .subscribe((token: string) => {
          if (this._utilService.isNulo(token)) {
            resolve(token);
          } else {
            reject('Erro ao gerar token do reCAPTCHA');
          }
        });
    });
  }

  postRecaptcha(payload: any): Observable<any> {
    const url = this._urlService.getApiRecaptcha();

    const headers = new HttpHeaders({
      'Content-Type': 'application/json; charset=UTF-8',
    });

    return this._httpClient.post(url, payload, { headers });
  }
}