<section class="flex justify-center w-full bg-white">

  <div class="max-w-[1280px] flex flex-col items-center gap-16 px-8 lg:px-0 py-12 lg:py-28 ">

    <h2 class="hidden lg:block text-4xl lg:text-[42px] font-bold">Veja como é fácil ativar sua tag</h2>

    <img src="assets/images/video-mockup.png" alt="video" class="hidden lg:block max-w-[460px] cursor-pointer shadow rounded-xl" (click)="toggleModal()">

    <div class="flex flex-col lg:flex-row justify-center items-center mt-16">
      <ftc-icon-svg [icon]="'logo-sem-parar'" [height]="140" class="w-28 lg:w-40 mx-auto mb-8 lg:mt-0"></ftc-icon-svg>

      <div class="lg:w-3/5 text-center lg:text-left flex flex-col gap-4">
        <p class="text-[32px] txt-text font-bold">Tecnologia Sem Parar</p>

        <p class="text-xl lg:text-2xl">
          Sem Parar é uma empresa pioneira no ramo de pagamento automático e conta com a maior cobertura do Brasil.
        </p>
      </div>
    </div>
  </div>
</section>

<ftc-video *ngIf="openModalVideo" [video]="_urlService.videoTutorialDesbloquearTag" (fecharModal)="toggleModal()"></ftc-video>