import { NgIf } from '@angular/common';
import { Component } from '@angular/core';
import { AppAbstract } from 'src/app/app.abstract';
import { IconSvgComponent } from 'src/app/components/icon-svg/icon-svg.component';
import { VideoComponent } from 'src/app/modals/video/video.component';

@Component({
    selector: 'ftc-veja-facil',
    templateUrl: './veja-facil.component.html',
    standalone: true,
    imports: [IconSvgComponent, VideoComponent, NgIf]
})
export class VejaFacilComponent extends AppAbstract {
    protected openModalVideo: boolean = false;

    protected toggleModal(): void {
        this.openModalVideo = !this.openModalVideo;
    }
}
